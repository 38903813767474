import { Box, Button, Divider, Grid, LinearProgress, Paper, TextField, Typography } from "@mui/material";
import { AxiosError } from "axios";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { publicService } from "../../../api/services/public";
import { CLEAR_ANNOUNCEMENT, SET_ANNOUNCEMENT } from "../../../redux/reducers/announcement";
import { State } from "../../../redux/store";
import { Announcement } from "../../../types/announcement";
import { useSnack } from "../../../utils/useSnack";
import {adminService} from "../../../api/services/admin";

export default function AnnouncementDetails({
  announcementEditing,
  setAnnouncementEditing
}: {
  announcementEditing?: boolean;
  setAnnouncementEditing?: Dispatch<SetStateAction<boolean>>
}) {
  const { t } = useTranslation();
  const { snack } = useSnack();
  const navigate = useNavigate();
  const state = useSelector((state: State) => state);
  const announcement: Announcement | null = state.announcement.announcement;
  const dispatch = useDispatch();

  const location = useLocation();
  const pathArray = location.pathname.split('/');
  const announcementId = pathArray[pathArray.length - 1];

  const [loading, setLoading] = useState<boolean>(false);

  async function getAnnouncement() {
    try {
      setLoading(true);
      const response = await publicService.getAnnouncement(announcementId);

      if (response && response.status === "success") {
        dispatch({ type: SET_ANNOUNCEMENT, payload: response.data })
      } else {
        snack(t("snack.error.announcement_details"), "error")
      }
    } catch (error) {
      const err = error as AxiosError;
      console.error(err.response);
      snack(t("snack.error.announcement_details"), "error");
    } finally {
      setLoading(false);
    }
  }

  function onClose() { 
    navigate("/admin-announcements");
    dispatch({ type: CLEAR_ANNOUNCEMENT })
  }

  function onEdit() {
    setAnnouncementEditing && setAnnouncementEditing(true);
  }

  async function onDelete() {
    try {
      const response = await adminService.deleteAnnouncement(announcementId);
      if (response && response.status === "success") {
        snack(t("snack.success.announcement_deleted"), "success");
        navigate("/admin-announcements");
        dispatch({ type: CLEAR_ANNOUNCEMENT })
      } else {
        snack(t("snack.error.delete_announcement"), "error")
      }

    } catch (error) {
      const err = error as AxiosError;
      console.error(err.response);
      snack(t("snack.error.delete_announcement"), "error");
    }
  }

  useEffect(() => {
    if (!announcement?.id) {
      getAnnouncement();
    }

    return () => {
      dispatch({ type: CLEAR_ANNOUNCEMENT })
    }
  }, []);

  return (
    <Box>
      {loading
        ? <Box margin={"0 15px"}>
            <LinearProgress sx={{ mt: 3, mb: 1 }} color="primary" />
            <Typography>{t("announcements_page.details.loading")}</Typography>
          </Box>

        : <Paper sx={{ pl: 4, pr: 4, pt: 2, pb: 4 }} elevation={10}>
            <Grid container spacing={3} justifyContent="space-between">
              <Grid item>
                <Typography component="h5" variant="h5" 
                            sx={{ fontWeight: 700 }}>
                  {t("announcements_page.details.title")}
                </Typography>
              </Grid>
            </Grid>
    
            <Divider sx={{ mt: 2, mb: 6, width: "100%" }} />
    
            <Grid container rowSpacing={3}>
              <Grid container columnSpacing={3} rowSpacing={3}>
                <Grid item xs={12} sm={6} md={8}>
                  <TextField
                    fullWidth color="primary" size="small"
                    label={t("auction.fields.announcement.title")}
                    value={announcement?.title ?? "- - -"}
                    InputProps={{ readOnly: true }}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <TextField 
                    fullWidth size="small" color="primary"
                    label={t("auction.fields.announcement.published_time")}
                    value={announcement?.publishedTime.replace('T', " ") ?? "- - -"}
                    InputProps={{ readOnly: true }}
                  />
                </Grid>
                
                <Grid item xs={12}>
                  <TextField
                    multiline rows={8} fullWidth color="primary" type="text" size="small"
                    label={t("auction.fields.announcement.content")}
                    value={announcement?.content ?? "- - -"}
                    InputProps={{ readOnly: true }}
                  />
                </Grid>
              </Grid>
            </Grid>
    
            <Divider sx={{ mt: 6, mb: 2, width: "100%" }} />
    
            <Grid item container spacing={2} justifyContent="center" sx={{ pt: 2 }}>
              <Grid item xs="auto">
                <Button fullWidth variant="contained" size="large"
                        onClick={onClose} color="secondary"
                >
                  {t("announcements_page.details.close")}
                </Button>
              </Grid>
              <Grid item xs="auto">
                <Button fullWidth variant="contained" color="primary"
                        onClick={onEdit} size="large">
                  {t("announcements_page.details.edit_announcement")}
                </Button>
              </Grid>
              <Grid item xs="auto">
                <Button fullWidth variant="contained" color="error"
                        onClick={onDelete} size="large">
                  {t("announcements_page.details.delete_announcement")}
                </Button>
              </Grid>
            </Grid>
          </Paper>
      }
    </Box>
  )
}