import { AnyAction } from "redux"

export const SET_AUCTION = "SET_AUCTION";

const initialState = {
  auction: null,
}

export const auctionReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case SET_AUCTION: return { ...state,
      auction: action.payload
    }

    default: return state
  }
}
