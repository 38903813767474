import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import {useTheme} from '@mui/material/styles';
import {colors, LinearProgress, Typography} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import WebbeeLogo from '../../../../svg/logos/Webbee';
import {useSelector} from "react-redux";
import {State} from '../../../../redux/store';
import {dateStringToTime} from '../../../../utils/dates';
import {useTranslation} from 'react-i18next';

const Topbar = ({
                    themeMode,
                    themeToggler,
                    setThemePalette,
                    onSidebarOpen,
                    paletteType,
                }: {
    themeMode: "light" | "dark";
    themeToggler: () => void;
    setThemePalette: (type?: string) => void;
    onSidebarOpen: () => void;
    paletteType: string;
}) => {
    const {t, i18n} = useTranslation();
    const state = useSelector((state: State) => state);
    const time = dateStringToTime(state.public.currentTime);

    return (
        <Box
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'center'}
            width={'100%'}
        >
            <Box display={'flex'} alignItems={'center'}>
                <Box marginRight={{xs: 1, sm: 2}} sx={{display: {md: 'none', sm: 'block'}}}>
                    <IconButton onClick={onSidebarOpen} aria-label="Menu">
                        <MenuIcon/>
                    </IconButton>
                </Box>
                <Box
                    display={'flex'}
                    alignItems="baseline"
                    component="a"
                    href="/"
                    title="bidhub"
                    height={{xs: 28, md: 32}}
                    width={45}
                >
                    <WebbeeLogo width="100%" height="100%"/>
                </Box>
            </Box>
            <Box display="flex" alignItems={'center'} gap={1.5} height={40}>
                <Box sx={{display: {xs: 'none', md: 'flex'}}} alignItems={'center'} gap={1.5}>
                    <Box>
                        <Link
                            underline="none"
                            component="a"
                            href="/page-announcements"
                            color="textPrimary"
                        >
                            {t("pages.announcements")}
                        </Link>
                    </Box>
                    <Box>
                        <Link
                            underline="none"
                            component="a"
                            href="/page-auctions"
                            color="textPrimary"
                        >
                            {t("pages.contests")}
                        </Link>
                    </Box>
                    <Box>
                        <Link
                            underline="none"
                            component="a"
                            href="/page-documentation"
                            color="textPrimary"
                        >
                            {t("pages.documentation")}
                        </Link>
                    </Box>
                    <Box>
                        <Link
                            underline="none"
                            component="a"
                            href="/page-contacts"
                            color="textPrimary"
                        >
                            {t("pages.contacts")}
                        </Link>
                    </Box>
                </Box>

                <Box
                    width={{xs: 70}}
                    textAlign="center"
                >
                    <Link underline={i18n.language === "ru" ? "always" : "none"} style={{ cursor: 'pointer' }}
                          component="a"
                          onClick={() => i18n.changeLanguage("ru")}
                    >
                        RU
                    </Link>
                    &nbsp;
                    <Link underline={i18n.language === "kk" ? "always" : "none"} style={{ cursor: 'pointer' }}
                          component="a"
                          onClick={() => i18n.changeLanguage("kk")}
                    >
                        KZ
                    </Link>
                </Box>
                <Box
                    width={{xs: 70}}
                    textAlign="center"
                >
                    {time
                        ? <Typography color={themeMode === 'light' ? 'primary' : 'secondary'}>
                            {time?.hours}:{time?.minutes}:{time?.seconds}
                        </Typography>
                        : <LinearProgress color={themeMode === 'light' ? 'primary' : 'secondary'}/>
                    }
                </Box>
            </Box>
        </Box>
    );
};

Topbar.propTypes = {
    onSidebarOpen: PropTypes.func,
    themeToggler: PropTypes.func.isRequired,
    themeMode: PropTypes.string.isRequired,
    setThemePalette: PropTypes.func.isRequired,
    paletteType: PropTypes.string.isRequired,
};

export default Topbar;
