import { AuctionState } from "./enums";

export function auctionStateArray(
  direction: "before" | "after" | "andBefore" | "andAfter", 
  state: AuctionState
) {
  return Object.keys(AuctionState).filter((key, idx, array) => {
    if (!state) return key;
    if (direction === "before" && idx < array.indexOf(AuctionState[state])) { return key }
    if (direction === "after" && idx > array.indexOf(AuctionState[state])) { return key }
    if (direction === "andBefore" && idx <= array.indexOf(AuctionState[state])) { return key }
    if (direction === "andAfter" && idx >= array.indexOf(AuctionState[state])) { return key }
  });
}