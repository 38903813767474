export const pages = [
  {
    title: 'pages.account.company-contests',
    id: 'company-contests',
    pages: [
      {
        title: 'pages.account.tender',
        href: '/account-tender',
      },
      {
        title: 'pages.account.price-offer',
        href: '/account-price-offer',
      },
      {
        title: 'pages.account.single-source',
        href: '/account-single-source',
      },
      {
        title: 'pages.account.auction',
        href: '/account-auction',
      },
    ],
  },
  {
    title: 'pages.account.contests-participation',
    id: 'contests-participation',
    pages: [
      {
        title: 'pages.account.auctions-actual',
        href: '/account-auctions-actual',
      },
      {
        title: 'pages.account.auctions-participated',
        href: '/account-auctions-participated',
      },
    ],
  },
  {
    title: 'pages.account.company',
    id: 'company',
    pages: [
      {
        title: 'pages.account.files',
        href: '/account-files',
      },
      {
        title: 'pages.account.company-profile',
        href: '/account-company-profile',
      },
      {
        title: 'pages.account.employees',
        href: '/account-employees',
      },
    ],
  },
];
