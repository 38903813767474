import React from "react";
import { Box, Chip, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Review } from "../types/auction";
import { dateToMuiDateTimeString } from "../utils/dates";

export function Reviews({ reviews }: { reviews: Review[] | null }) {
  const { t } = useTranslation();

  return (
    <Box>
      <Grid container>
        <Grid item sx={{ mb: 1 }}>
          <Typography variant="h6">{t("auction.sections.reviews")}</Typography>
        </Grid>
        <Grid item container rowSpacing={1}>
          {(!reviews || !reviews.length)
            ? <Typography>{t("auction.no_reviews")}</Typography>
            : reviews.map((review, idx) => (
                <Grid key={idx} item container columnSpacing={5} alignItems="center" rowSpacing={1}>
                  <Grid item sx={{ width: 150 }}>
                    <Chip label={t(`review_result.${review.result}`)} />
                  </Grid>
                  <Grid item>
                    <Typography>
                      {dateToMuiDateTimeString(new Date(review.reviewTime))}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography>{review.person.name}</Typography>
                  </Grid>
                </Grid>
              ))
          }
        </Grid>
      </Grid>
    </Box>
  )
}
