import { AxiosResponse } from "axios";
import { PaginationResponse, Response } from "../../types/api";
import publicInstance from "../instances/public";
import download from "downloadjs";
import { Auction } from "../../types/auction";
import { Announcement } from "../../types/announcement";

async function getServerTime() {
	return publicInstance
		.get("/v1/public/time")
		.then((res: AxiosResponse<Response<{ now: string }>>) => res.data)
}

async function getAuctions(
	text: string, page: number, pageSize: number, auctionType?: string
) {
	const query = `query=${text}&page=${page}&page_size=${pageSize}&type=${auctionType ?? ""}`;

	return publicInstance
		.get(`/v1/public/auctions?${query}`)
		.then((res: AxiosResponse<PaginationResponse<Auction[]>>) => res.data)
}

async function getAuction(auctionNo: string) {
	return publicInstance
		.get(`/v1/public/auction/${auctionNo}`)
		.then((res: AxiosResponse<Response<Auction>>) => res.data)
}

async function getFileToken(auctionId: string, fileId: string) {
	return publicInstance
		.get(`/v1/public/auction/${auctionId}/attachment/${fileId}`)
		.then((res: AxiosResponse<Response<{ id: string }>>) => res.data)
}

async function downloadFile(fileToken: string, fileName: string) {
	return publicInstance({
		url: `/v1/public/file/${fileToken}`,
		method: 'get',
		responseType: 'blob',
	})
		.then((res: AxiosResponse<any>) => {
			const content = res.headers["content-type"];
			download(res.data, fileName, content)
		})
}

async function getAnnouncements(
  page: number, pageSize: number
) {
  const query = `page=${page}&page_size=${pageSize}`;

  return publicInstance
    .get(`/v1/public/news?${query}`)
    .then((res: AxiosResponse<PaginationResponse<Announcement[]>>) => res.data)
}

async function getAnnouncement(announcementId: string) {
  return publicInstance
    .get(`/v1/public/news/${announcementId}`)
    .then((res: AxiosResponse<Response<Announcement>>) => res.data)
}

export const publicService = {
  getServerTime,
	getAuctions,
	getAuction,
	getFileToken,
	downloadFile,
	getAnnouncements,
	getAnnouncement
}
