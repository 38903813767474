export const pages = [
  {
    title: 'pages.admin.companies',
    id: 'companies',
    pages: [
      {
        title: 'pages.admin.companies-registry',
        href: '/admin-companies',
      },
    ],
  },
  {
    title: 'pages.admin.users',
    id: 'users',
    pages: [
      {
        title: 'pages.admin.users-registry',
        href: '/admin-users',
      },
    ],
  },
  {
    title: 'pages.admin.announcements',
    id: 'announcements',
    pages: [
      {
        title: 'pages.admin.announcements-registry',
        href: '/admin-announcements',
      },
    ],
  },
];
