import { AxiosError } from "axios";
import { useRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import { publicService } from "../api/services/public";
import { SET_CURRENT_TIME } from "../redux/reducers/public";

export function useServerTime() {
  const dispatch = useDispatch();
  const timeOffset = useRef(0);
  const timeCurrent = useRef(new Date);

  async function getServerTime() {
    try {
      const result = await publicService.getServerTime();
      if (result.data && result.status === "success") {
        const serverDate = new Date(result.data.now)
        const localDate = new Date()
        timeOffset.current = serverDate.getTime() - localDate.getTime();
        return
      }
    }
    catch (error) {
      const err = error as AxiosError;
      console.error("ERROR", err.response)
    }
  }

  function calculateTime() {
    const currentTime = new Date();
    const currentTimeMillis = new Date().getTime()
    currentTime.setTime(currentTimeMillis + timeOffset.current);
    timeCurrent.current = currentTime;

    dispatch({ type: SET_CURRENT_TIME, payload: timeCurrent.current });
  }

  useEffect(() => { 
    getServerTime();
    let interval = setInterval(calculateTime, 1000);
    return () => { clearInterval(interval) }
  }, []);

  return { currentTime: timeCurrent.current }
}