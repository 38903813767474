import {t} from "i18next";
import * as Yup from "yup";
import { NewCompanyContactsForm } from "../../../../../types/newCompany";
import { maskedPhoneNumber } from "../../../../../utils/regexs";

export const initialValues: NewCompanyContactsForm = {
  legalAddress: "",
  actualAddress: "",
  phone: "",
  email: ""
};

export function ValidationSchema() {
  return Yup.object({
    legalAddress: Yup.string().required(t("validation.required")),
    actualAddress: Yup.string().required(t("validation.required")),
    phone: Yup.string()
      .required(t("validation.required"))
      .matches(maskedPhoneNumber, t("validation.incorrect")),
    email: Yup.string()
      .required(t("validation.required"))
      .email(t("validation.incorrect")),
  });
}