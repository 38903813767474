import { AxiosResponse } from "axios";
import { Response } from "../../types/api";
import { Company, CompanyFile, Employee } from "../../types/company";
import { CompanyExists } from "../../types/newAuction";
import protectedInstance from "../instances/protected";

async function getCompanies() {
  return protectedInstance
    .get("/companies")
    .then((res: AxiosResponse<Response<Company[]>>) => res.data)
}

async function getCompany(companyId: string) {
	return protectedInstance
		.get(`/company/${companyId}`)
		.then((res: AxiosResponse<Response<Company>>) => res.data);
};

async function getEmployees(companyId: string) {
	return protectedInstance
		.get(`/company/${companyId}/users`)
		.then((res: AxiosResponse<Response<Employee[]>>) => res.data);
};

async function saveNewEmployee(
  companyId: string, data: { username: string, identity: string }
) {
	return protectedInstance
		.post(`/company/${companyId}/user`, data)
		.then((response: AxiosResponse<Response<null>>) => response.data);
};

async function getFileToken(companyId: string, fileId: string) {
  return protectedInstance
    .get(`/company/${companyId}/file/${fileId}`)
    .then((res: AxiosResponse<Response<{ id: string }>>) => res.data)
}

async function deleteFile(companyId: string, fileId: string, operation: "delete" | "archive") {
  return protectedInstance
    .post(`/company/${companyId}/file/${fileId}/${operation}`, null)
    .then((res: AxiosResponse<Response<null>>) => res.data)
}

async function getCompanyFiles(companyId: string, fileCategory?: string) {
  const query = fileCategory ? `?categories=${fileCategory}` : "";
	
	return protectedInstance
		.get(`/company/${companyId}/files${query}`)
		.then((res: AxiosResponse<Response<CompanyFile[]>>) => res.data)
}

async function saveNewFile(companyId: string, formData: FormData) {
	return protectedInstance
		.post(`/company/${companyId}/file`, formData)
		.then((res: AxiosResponse<Response<CompanyFile>>) => res.data)
}

async function getPassport(companyId: string, auctionId: string) {
  return protectedInstance
    .get(`/company/${companyId}/auction/${auctionId}/passport`)
    .then((res: AxiosResponse<Response<{ passport: string }>>) => res.data)
}

async function checkCompany(itn: string) {
  return protectedInstance
    .get(`/check/company/bin/${itn}`)
    .then((res: AxiosResponse<Response<CompanyExists>>) => res.data)
}

export const companyService = {
  getCompanies,
  getCompany,
  getEmployees,
  saveNewEmployee,
  getFileToken,
  deleteFile,
  getCompanyFiles,
  saveNewFile,
  getPassport,
  checkCompany
}