import React from "react";
import {Autocomplete, Grid, TextField, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns"
import ruLocale from 'date-fns/locale/ru';
import kkLocale from "date-fns/locale/kk";
import {Field, FieldProps, Formik, FormikProps} from "formik";
import {initialValues, ValidationSchema} from "./formik";
import {NewAuctionTermsForm} from "../../../../../types/newAuction";
import {DeliveryCondition} from "../../../../../utils/enums";
import {useDictionary} from "../../../../../utils/useDictionary";

export const TermsFields = ({
                                formikRef, editingInitialState
                            }: {
    formikRef: React.RefObject<FormikProps<NewAuctionTermsForm>>;
    editingInitialState?: NewAuctionTermsForm
}) => {
    const onSubmit = (values: NewAuctionTermsForm) => {
    };
    const {t, i18n} = useTranslation();

    const deliveryCondition = useDictionary(
        DeliveryCondition,
        (type) => ({value: type, label: t(`delivery_condition.${type}`)}),
        editingInitialState
            ? editingInitialState.deliveryCondition
            : {value: DeliveryCondition.exw, label: t("delivery_condition.exw")}
    );

    React.useEffect(() => {
        formikRef.current?.setFieldValue(
            "deliveryCondition", deliveryCondition.selected, false
        );
    }, [deliveryCondition.selected]);

    return (
        <Formik innerRef={formikRef}
                initialValues={editingInitialState || initialValues}
                validationSchema={ValidationSchema()}
                onSubmit={onSubmit}
                validateOnMount
        >
            {(frops: FormikProps<NewAuctionTermsForm>) => (
                <LocalizationProvider dateAdapter={AdapterDateFns}
                                      adapterLocale={i18n.language == "kk" ? kkLocale : ruLocale}>
                    <Grid container columnSpacing={3} rowSpacing={1}>
                        <Grid item xs={12} sx={{mb: 1}}>
                            <Typography variant="h6">
                                {t("auction.sections.terms")}
                            </Typography>
                        </Grid>

                        <Grid item container columnSpacing={3} rowSpacing={1}>
                            <Grid item xs={12} sm={6} md={4}>
                                <Autocomplete
                                    disablePortal size="small"
                                    options={deliveryCondition.dictionary}
                                    isOptionEqualToValue={(option, value) => option.value === value.value}
                                    value={deliveryCondition.selected}
                                    onChange={deliveryCondition.onSelectChange}
                                    renderInput={params =>
                                        <Field name={"deliveryCondition"}>
                                            {({field, form, meta}: FieldProps) => (
                                                <TextField {...params} fullWidth color="primary"
                                                           label={t("auction.dictionary.deliveryCondition")}
                                                           error={(meta.touched && meta.error) ? true : false}
                                                           helperText={meta.touched && meta.error}
                                                />
                                            )}
                                        </Field>
                                    }
                                />
                            </Grid>

                            <Grid item xs={12} sm={6} md={4}>
                                <Field name={"deliveryTime"}>
                                    {({field, form, meta}: FieldProps) => (
                                        <TextField {...field}
                                                   fullWidth size="small" color="primary"
                                                   label={t("auction.fields.deliveryTime")}
                                                   error={(meta.touched && meta.error) ? true : false}
                                                   helperText={meta.touched && meta.error}
                                        />
                                    )}
                                </Field>
                            </Grid>
                        </Grid>

                    </Grid>
                </LocalizationProvider>
            )}
        </Formik>
    )
}
