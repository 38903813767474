import {t} from "i18next";
import { Auction } from "../types/auction";
import { AuctionType } from "./enums";

export function switchStatusText(
  auction: Auction | null
) {
  if (!auction) return;

  switch(auction.auctionType) {
    case AuctionType.auction: return t("auction.status_text.auction")
    case AuctionType.tender: return t("auction.status_text.tender")
    case AuctionType.price_offer: return t("auction.status_text.price_offer")
    case AuctionType.single_source: return t("auction.status_text.single_source")

    default: return t("auction.status_text.contest")
  }
}

export function switchTitleText(
  auction: Auction | null
) {
  if (!auction) return;

  switch(auction.auctionType) {
    case AuctionType.auction: return t("auction.title_text.auction")
    case AuctionType.tender: return t("auction.title_text.tender")
    case AuctionType.price_offer: return t("auction.title_text.price_offer")
    case AuctionType.single_source: return t("auction.title_text.single_source")

    default: return t("auction.title_text.contest")
  }
}