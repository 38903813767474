import { Box, Button, Grid, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { adminService } from "../../../api/services/admin";
import { Column, columnStyle, DateColumn, largeColumnStyle } from "../../../common/Columns";
import Container from "../../../common/Container";
import NoRowsOverlay from "../../../common/NoRowsOverlay";
import { Announcement } from "../../../types/announcement";
import { useSnack } from "../../../utils/useSnack";

export default function Announcements() {
  const { t } = useTranslation();
  const { snack } = useSnack();
  const navigate = useNavigate();

  const [announcements, setAnnouncements] = useState<Announcement[]>([]);
	const [loading, setLoading] = useState(false);
	const [page, setPage] = useState(0);
	const [pageSize, setPageSize] = useState(5);
	const [totalElements, setTotalElements] = useState(0);

  const onPageChange = (newPage: number) => setPage(newPage);
	const onPageSizeChange = (newSize: number) => {setPage(0); setPageSize(newSize)};

  function onCreateAnnouncement() {
    navigate("/admin-announcements/create")
  }

  async function getAnnouncements() {
    try {
      setLoading(true);
      const response = await adminService.getAnnouncements(page, pageSize);

      if (response.status === "success" && response.data) {
        setAnnouncements(response.data.data);
        setTotalElements(response.data.totalElements);
      }
    } catch (error) {
      snack(t("snack.error.announcements_registry"), "error");
    } finally {
      setLoading(false);
    }
  }

  function goToAnnouncementDetails(id: string) {
    navigate(`/admin-announcements/${id}`)
  }

  useEffect(() => {
    getAnnouncements()
  }, [page, pageSize]);
  
  return (
    <Box>
      <Container paddingY={{ xs: 2, sm: 4 }} maxWidth="unset">
        <Box marginBottom={4}>
          <Typography
            variant="h3"
            gutterBottom
            sx={{
              fontWeight: 700,
            }}
          >
            {t("cabinet.announcements.registry")}
          </Typography>
        </Box>

        <Grid item container spacing={2} alignItems={"center"} mb={2}>
					<Grid item xs={12} sm={5} md={4} lg={3}>
						<Button fullWidth color="primary" variant="contained"
							onClick={onCreateAnnouncement}>
							<span style={{ whiteSpace: 'nowrap' }}>
								{t("cabinet.announcements.add_new_announcement")}
							</span>
						</Button>
					</Grid>
				</Grid>

        <Box>
					<DataGrid 
						components={{ NoRowsOverlay: () => <NoRowsOverlay /> }}
						sx={{
              height: 500,
              "& .MuiDataGrid-cell": {
                whiteSpace: "pre-wrap"
              },
            }}
						columnBuffer={3}
						rows={announcements} 
						columns={[
							Column("title", 250, columnStyle),
							Column("content", 500, largeColumnStyle),
							DateColumn("publishedTime", 200, columnStyle)
						]}
						onRowClick={(rowData) => goToAnnouncementDetails(rowData.row.id)}
						page={page} onPageChange={onPageChange}
						pageSize={pageSize} onPageSizeChange={onPageSizeChange}
						rowCount={totalElements} rowsPerPageOptions={[5, 10, 15]}
						loading={loading} paginationMode="server"
						density="comfortable" style={{ whiteSpace: "pre-wrap" }}
					/>
				</Box>
      </Container>
    </Box>
  )
}