import { useState, useEffect, CSSProperties } from "react";
import { Autocomplete, Box, Button, Grid, IconButton, TextField, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useLocation, useNavigate } from "react-router-dom";
import Container from "../../../common/Container";
import { useSnack } from "../../../utils/useSnack";
import { Auction } from "../../../types/auction";
import ClearIcon from '@mui/icons-material/Clear';
import { useTranslation } from "react-i18next";
import { publicService } from "../../../api/services/public";
import { AuctionType } from "../../../utils/enums";
import { useDictionary } from "../../../utils/useDictionary";
import NoRowsOverlay from "../../../common/NoRowsOverlay";
import { Column, columnStyle, CompanyTitleColumn, GrossColumn, largeColumnStyle } from "../../../common/Columns";

export default function Auctions() {
  const { t } = useTranslation();
  const { snack } = useSnack();
  const location = useLocation();
  const navigate = useNavigate();

  function goToAuctionDetails(auctionNo: string) {
    navigate(`/page-auctions/${auctionNo}`)
  }

  const [auctions, setAuctions] = useState<Auction[]>([]);
	const [loading, setLoading] = useState(false);
	const [searchText, setSearchText] = useState("");
	const [page, setPage] = useState(0);
	const [pageSize, setPageSize] = useState(8);
	const [totalElements, setTotalElements] = useState(0);

  const [sort, setSort] = useState(false);
  const auctionType = useDictionary(
    AuctionType, 
    (type) => ({value: type, label: t(`auction_type.${type}`)}),
    { value: AuctionType.auction, label: t("auction_type.auction") }
  );

  async function getAuctions(text: string) {
		try {
			setLoading(true);
      const response = await publicService.getAuctions(
        text, page, pageSize,
        sort ? auctionType.selected?.value : ""
      );

			if (response.status === "success" && response.data) {
				setAuctions(response.data.data);
				setTotalElements(response.data.totalElements);
			}
			else { snack(t("snack.error.auctions"), "error");}
    }
    catch (err) { snack(t("snack.error.auctions"), "error");}
		finally { setLoading(false) }
	};

  const search = (text: string) => getAuctions(text);
	const onClearSearch = () => {setSearchText("");	search("")};
	const onPageChange = (newPage: number) => setPage(newPage);
	const onPageSizeChange = (newSize: number) => {setPage(0); setPageSize(newSize)};

  useEffect(() => { 
    getAuctions(searchText) 
  }, [location.pathname, page, pageSize, sort, auctionType.selected?.value]);
  
  return (
    <Box>
      <Container paddingY={{ xs: 2, sm: 4 }}>
        <Box marginBottom={4}>
          <Typography
            variant="h3"
            gutterBottom
            sx={{
              fontWeight: 700,
            }}
          >
            {t("auctions_page.contests")}
          </Typography>
        </Box>

        <Grid item marginBottom={2} container spacing={2} alignItems={"flex-start"}>
          <Grid item xs={12} sm={3.5}>
            <Autocomplete
              disablePortal size="small" disabled={!sort} sx={{ mb: .5 }}
              options={auctionType.dictionary}
              isOptionEqualToValue={(option, value) => option.value === value.value}
              value={auctionType.selected} 
              onChange={auctionType.onSelectChange}
              renderInput={params => 
                <TextField {...params} fullWidth color="primary" 
                          name="auctionType" label={t("auction.dictionary.auctionType")}
                /> 
              } 
            />
            {sort
              ? <Button color="primary" onClick={() => setSort(false)}>
                  {t("auctions_page.disable_auction_type_filter")}
                </Button>
              : <Button color="primary" onClick={() => setSort(true)}>
                  {t("auctions_page.enable_auction_type_filter")}
                </Button>
            }
          </Grid>
					<Grid item xs={12} sm={5.5}>
						<TextField fullWidth color="primary" variant="outlined" size="small" 
							label={t("auctions_page.search_auction")}
							value={searchText} onChange={e => setSearchText(e.target.value)}
							onKeyPress={e => {if (e.key === "Enter") { search(searchText) } }}
							InputProps={{
								endAdornment: searchText ? (
									<IconButton size="small" onClick={onClearSearch}>
										<ClearIcon />
									</IconButton>
								) : undefined
							}}
						/>
					</Grid>
					<Grid item xs={12} sm={3}>
						<Button fullWidth color="primary" variant="contained"
							onClick={() => search(searchText)}
						>
							{t("auctions_page.search")}
						</Button>
					</Grid>
				</Grid>

        <Box>
          <DataGrid 
            components={{ NoRowsOverlay: () => <NoRowsOverlay /> }}
            sx={{
              height: 600,
              "& .MuiDataGrid-cell": {
                whiteSpace: "pre-wrap"
              },
            }}
						columnBuffer={8} // columns length
            rows={auctions} 
            columns={[
              Column("auctionType", 150, columnStyle, "auction_type"),
              Column("state", 120, columnStyle, "auction_state"),
              Column("participantCount", 100, columnStyle),
              Column("bidCount", 125, columnStyle),
              Column("auctionNo", 140, { ...columnStyle, ...largeColumnStyle }),
              Column("title", 165, { ...columnStyle, ...largeColumnStyle }),
              CompanyTitleColumn(165, columnStyle),
              GrossColumn(125, columnStyle)
            ]}
            onRowClick={(rowData) => goToAuctionDetails(rowData.row.auctionNo)}
            page={page} onPageChange={onPageChange}
            pageSize={pageSize} onPageSizeChange={onPageSizeChange}
            rowCount={totalElements} rowsPerPageOptions={[4, 8, 16]}
            loading={loading} paginationMode="server"
            density="comfortable" style={{ whiteSpace: "pre-wrap" }}
          />
        </Box>
      </Container>
    </Box>
  )
}
