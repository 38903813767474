import { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useSnack } from '../../../utils/useSnack';
import Container from '../../../common/Container';
import { adminService } from '../../../api/services/admin';
import { User } from '../../../types/auth';
import NoRowsOverlay from '../../../common/NoRowsOverlay';
import { AuthoritiesColumn, Column, columnStyle } from '../../../common/Columns';

export default function Users() {
	const { snack } = useSnack();
  const { t } = useTranslation();

	const [users, setUsers] = useState<User[]>([]);
	const [loading, setLoading] = useState(false);
	const [page, setPage] = useState(0);
	const [pageSize, setPageSize] = useState(5);
	const [totalElements, setTotalElements] = useState(0);

	const onPageChange = (newPage: number) => setPage(newPage);
	const onPageSizeChange = (newSize: number) => {setPage(0); setPageSize(newSize)};

  const getUsers = async () => {
		try {
			setLoading(true);
      const response = await adminService.getUsers(page, pageSize);
			if (response.status === "success" && response.data) {
				setUsers(response.data.data);
				setTotalElements(response.data.totalElements);
			}
			else { snack(t("snack.error.users_registry"), "error") }
    }
    catch (err) { snack(t("snack.error.users_registry"), "error") }
		finally { setLoading(false) }
	};

  const navigate = useNavigate();

	useEffect(() => { getUsers() }, [page, pageSize]);

	return (
		<Box>
      <Container paddingY={{ xs: 2, sm: 4 }} maxWidth="unset">
        <Box marginBottom={4}>
          <Typography
            variant="h3"
            gutterBottom
            sx={{
              fontWeight: 700,
            }}
          >
            {t("cabinet.users.registry")}
          </Typography>
        </Box>

				<Box>
					<DataGrid 
						components={{ NoRowsOverlay: () => <NoRowsOverlay /> }}
						sx={{
              height: 500,
              "& .MuiDataGrid-cell": {
                whiteSpace: "pre-wrap"
              },
            }}
						columnBuffer={4}
						rows={users.length ? users.map((user, idx) => ({ ...user, id: idx })) : []}
						columns={[
							AuthoritiesColumn(200, columnStyle),
							Column("username", 200, columnStyle),
							Column("name", 200, columnStyle),
							Column("mobile", 200, columnStyle)
						]}
						page={page} onPageChange={onPageChange}
						pageSize={pageSize} onPageSizeChange={onPageSizeChange}
						rowCount={totalElements} rowsPerPageOptions={[5, 10, 15]}
						loading={loading} paginationMode="server"
						density="comfortable" style={{ whiteSpace: "pre-wrap" }}
					/>
				</Box>
			</Container>
		</Box>
	);
};
