import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import CssBaseline from '@mui/material/CssBaseline';
import getTheme from './theme';
import palettes from './common/paletteTypes';
import AOS from 'aos';
import {Authority, CompanyState} from './utils/enums';
import {Company} from "./types/company";
import {useNavigate} from "react-router-dom";
import {Introduction as IntroductionView} from "./views/account";
import {useSelector} from "react-redux";
import {State} from "./redux/store";

export const useDarkMode = () => {
  const [themeMode, setTheme] = useState('light');
  const [paletteType, setPalette] = useState(palettes[0]);
  const [mountedComponent, setMountedComponent] = useState(false);

  const setMode = (mode: "light" | "dark") => {
    window.localStorage.setItem('themeMode', mode);
    setTheme(mode);
  };

  const setThemePalette = (type: 'green' = 'green') => {
    const palette = palettes.indexOf(type) === -1 ? 'green' : type;
    window.localStorage.setItem('themePalette', palette);
    setPalette(palette);
  };

  const themeToggler = () => {
    themeMode === 'light' ? setMode('dark') : setMode('light');
  };

  useEffect(() => {
    const localTheme = window.localStorage.getItem('themeMode');
    localTheme ? setTheme(localTheme) : setMode('light');
    const localPalette = window.localStorage.getItem('themePalette') as 'green';
    localPalette ? setPalette(localPalette) : setThemePalette('green');
    setMountedComponent(true);
  }, []);

  return [
    themeMode,
    themeToggler,
    paletteType,
    setThemePalette,
    mountedComponent,
  ];
};

export default function WithLayout({
  component: Component,
  layout: Layout,
  role,
  ...rest
}: {
  component: React.FunctionComponent<any>;
  layout: React.FunctionComponent<any>;
  role?: Authority
}) {

  React.useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles?.parentElement?.removeChild(jssStyles);
    }

    AOS.init({
      once: true,
      delay: 50,
      duration: 500,
      easing: 'ease-in-out',
    });
  }, []);



  const [
    themeMode,
    themeToggler,
    paletteType,
    setThemePalette,
    mountedComponent,
  ] = useDarkMode();

  useEffect(() => {
    AOS.refresh();
  }, [mountedComponent, themeMode, paletteType]);

  return (
    <ThemeProvider theme={getTheme(themeMode, paletteType)}>
      {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
      <CssBaseline />
      <Paper elevation={0}>
        <Layout
          themeMode={themeMode}
          themeToggler={themeToggler}
          paletteType={paletteType}
          setThemePalette={setThemePalette}
          role={role ? role : Authority.USER}
        >
          <Component
            themeMode={themeMode}
            paletteType={paletteType}
            {...rest}
          />
        </Layout>
      </Paper>
    </ThemeProvider>
  );
}

WithLayout.propTypes = {
  component: PropTypes.elementType.isRequired,
  layout: PropTypes.elementType.isRequired,
};
