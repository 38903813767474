import React from "react";
import { Autocomplete, Box, Button, Divider, Grid, LinearProgress, List, ListItem, 
  ListItemText, Modal, TextField, Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";
import { AxiosError } from "axios";
import { useTranslation } from "react-i18next";
import AnimateHeight from "react-animate-height";
import { companyService } from "../../api/services/company";
import { State } from "../../redux/store";
import { Company, CompanyFile } from "../../types/company";
import { FileCategory } from "../../utils/enums";
import { useDictionary } from "../../utils/useDictionary";
import { Response } from "../../types/api";
import { useSnack } from "../../utils/useSnack";
import { modalStyle } from "../modalStyle";

export const ArchivedFilesModal = ({
  isOpen, onClose, pickFile, downloadFile, downloadingId, onOpenNew
}: {
  isOpen: boolean,
  onClose: () => void;
  pickFile: (file: CompanyFile) => void;
  downloadFile: (file: CompanyFile) => Promise<void>;
  downloadingId: string;
  onOpenNew: () => void;
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const state = useSelector((state: State) => state);
	const company: Company | null = state.company.company;
  const { snack } = useSnack();

  const [isLoading, setIsLoading] = React.useState(false);
  const [archivedFilesResponse, setArchivedFilesResponse] = 
    React.useState<Response<CompanyFile[]>>({
      code: "", data: null, status: "success"
    });
  const [archivedFiles, setArchivedFiles] = React.useState<CompanyFile[]>([]);

  const [sort, setSort] = React.useState(false);
  const fileCategory = useDictionary(
    FileCategory, 
    (type) => ({value: type, label: t(`file_category.${type}`)}),
    { value: FileCategory.other, label: t("file_category.other") }
  );

  const getFiles = async () => {
    if (!company) { return }

    try {
      setIsLoading(true);
      const result = await companyService.getCompanyFiles(
        company.id, 
        sort ? fileCategory.selected?.value : undefined
      );
      setArchivedFilesResponse(result);
      setArchivedFiles(result.data ? result.data : []);
    }
    catch (error) {
      const err = error as AxiosError;
      snack(t("snack.error.default"), "error"); 
    }
    finally { setIsLoading(false) }
  };

  React.useEffect(() => {
    if (company?.id) { getFiles() }
  }, [company, sort, fileCategory.selected?.value]);

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Box sx={modalStyle}>
        {/* header */}
          <Grid container spacing={2} justifyContent="space-between" sx={{ mb: 2 }}>
            <Grid item>
              <Typography component="h5" variant="h5" sx={{ fontSize: { xs: 14, sm: 20 } }}>
                {t("docs.archived_company_docs")}
              </Typography>
            </Grid>
            <Grid item xs={2.2} sm={1.2}>
              <Button onClick={onClose} sx={{ 
                color: "black"
              }}>
                <CloseIcon />
              </Button>
            </Grid>
          </Grid>
        {/* / header */}

        <Grid item>
          <Autocomplete
            disablePortal size="small" disabled={!sort} sx={{ mb: .5 }}
            options={fileCategory.dictionary}
            isOptionEqualToValue={(option, value) => option.value === value.value}
            value={fileCategory.selected} 
            onChange={fileCategory.onSelectChange}
            renderInput={params => 
              <TextField {...params} fullWidth color="primary" 
                         name="fileCategory" 
                         label={t("auction.dictionary.fileCategory")}
              /> 
            } 
          />
          {sort
            ? <Button color="primary" onClick={() => setSort(false)}>
                {t("docs.disable_category_sort")}
              </Button>
            : <Button color="primary" onClick={() => setSort(true)}>
                {t("docs.enable_category_sort")}
              </Button>
          }
        </Grid>

        <AnimateHeight height={(isLoading || downloadingId) ? 50 : "auto"} duration={200}>
          {isLoading
            ? <Box>
                <LinearProgress sx={{ mt: 3, mb: 1 }} color="primary" />
                <Typography>{t("docs.loading")}</Typography>
              </Box>
            : archivedFilesResponse
              ? archivedFiles.length
                ? <List sx={{ minHeight: 50, maxHeight: { xs: 350, sm: 550 }, overflowY: "scroll" }}>
                    {archivedFiles.map((file, idx) => (
                      <React.Fragment key={idx}>
                        <ListItem>
                          <Grid item xs={16}>
                            <ListItemText
                              primary={file.title}
                              secondary={
                                <React.Fragment>
                                  <Typography component={"span"} sx={{ display: "block" }}>
                                    {t(`file_category.${file.category}`)}
                                  </Typography>
                                  <Typography component={"span"} sx={{ display: "block" }}>
                                    {file.description}
                                  </Typography>
                                  <Typography component={"span"} sx={{ display: "block" }}>
                                    {file.name}
                                  </Typography>
                                </React.Fragment>
                              }
                            />
                          </Grid>

                          <Grid item container spacing={2} justifyContent="flex-end">
                            <Grid item>
                              <Button variant="contained" size="small" color="secondary"
                                      onClick={() => downloadFile(file)}
                                      disabled={downloadingId === file.id}
                              >
                                {t("docs.download")}
                              </Button>
                            </Grid>
                            <Grid item>
                              <Button variant="contained" color="primary" size="small"
                                      onClick={() => pickFile(file)}
                              >
                                {t("docs.attach")}
                              </Button>
                            </Grid>
                          </Grid>
                        </ListItem>
                        
                        {downloadingId === file.id &&
                          <LinearProgress sx={{ my: 1, mx: 2 }} color="primary" />                      
                        }

                        <Divider />
                      </React.Fragment>
                    ))}
                  </List>
                : <React.Fragment>
                    <Typography fontSize={14} mt={2}>
                      {sort 
                        ? t("docs.not_found")
                        : t("docs.no_archived_docs")
                      }
                    </Typography>

                    {!sort && 
                      <Grid item mt={2}>
                        <Button variant="contained" color="primary" size="small"
                                onClick={() => {onClose(); onOpenNew()}}
                        >
                          {t("docs.add")}
                        </Button>
                      </Grid>
                    }
                  </React.Fragment>
              : null
          }
        </AnimateHeight>
      </Box>
    </Modal>
  )
}
