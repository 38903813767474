import { Paper, Grid, Typography, Divider, Button } from "@mui/material";
import { AxiosError } from "axios";
import { FormikProps } from "formik";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { adminService } from "../../../api/services/admin";
import { NewCompanyCEOForm, NewCompanyCompanyForm, NewCompanyContactsForm, NewCompanyForm, NewCompanyOtherForm, NewCompanyOwnerForm } from "../../../types/newCompany";
import digitsOnly from "../../../utils/digitsOnly";
import { formValidation } from "../../../utils/formValidation";
import { useSnack } from "../../../utils/useSnack";
import { CEOFields } from "./components/CEO";
import { CompanyFields } from "./components/Company";
import { ContactsFields } from "./components/Contacts";
import { OtherFields } from "./components/Other";
import { OwnerFields } from "./components/Owner";

export default function NewCompany() {
  const { t } = useTranslation();
  const { snack } = useSnack();
  const navigate = useNavigate();

  const ownerFormikRef = useRef<FormikProps<NewCompanyOwnerForm>>(null);
  const ceoFormikRef = useRef<FormikProps<NewCompanyCEOForm>>(null);
  const companyFormikRef = useRef<FormikProps<NewCompanyCompanyForm>>(null);
  const contactsFormikRef = useRef<FormikProps<NewCompanyContactsForm>>(null);
  const otherFormikRef = useRef<FormikProps<NewCompanyOtherForm>>(null);

  const formikRefs = [
    ownerFormikRef, 
    ceoFormikRef,
    companyFormikRef,
    contactsFormikRef,
    otherFormikRef,
  ];

  const validateAll = () => formikRefs.forEach(ref => formValidation(ref));
  const allIsValid = () => formikRefs
    .map(ref => ref.current?.isValid)
    .every(item => item);

  function onClose() { navigate("/admin-companies") };
  
  async function submit() {
    const allRefsDefined = (
      ownerFormikRef.current &&
      ceoFormikRef.current &&
      companyFormikRef.current &&
      contactsFormikRef.current &&
      otherFormikRef.current &&
      true
    );

    validateAll();

    if (allRefsDefined && allIsValid()) {
      const owner = ownerFormikRef.current.values;
      const ceo = ceoFormikRef.current.values;
      const company = companyFormikRef.current.values;
      const contacts = contactsFormikRef.current.values;
      const other = otherFormikRef.current.values;

      const data: NewCompanyForm = {
        ownerUsername: owner.ownerUser?.username ?? "",
        ownerIdentity: owner.ownerIdentity,
        ceoName: ceo.ceoName,
        ceoPhone: digitsOnly(ceo.ceoPhone),
        itn: `kz:${company.itn}`,
        title: company.title,
        shortTitle: company.shortTitle,
        legalForm: company.legalForm.value,
        size: company.size.value,
        ownershipForm: company.ownershipForm.value,
        legalAddress: contacts.legalAddress,
        actualAddress: contacts.actualAddress,
        phone: digitsOnly(contacts.phone),
        email: contacts.email,
        reviewComment: other.reviewComment,
        isBroker: false
      };

      try {
        const response = await adminService.saveNewCompany(data);

        if (response && response.data && response.status === "success") {
          snack(t("snack.success.company_added"), "success"); 
          navigate("/admin-companies");
        }
        else { snack(t("snack.error.add_company"), "error") }
      }
      catch (error) {
        const err = error as AxiosError;
        console.log(err.response);
        snack(t("snack.error.add_company"), "error"); 
      }
    }
    else { snack(t("snack.warning.required_fields"), "warning"); }
  };

  return (
    <Paper sx={{ pl: 4, pr: 4, pt: 2, pb: 4 }} elevation={10}>
      <Grid container spacing={3} justifyContent="space-between">
        <Grid item>
          <Typography component="h5" variant="h5" 
                      sx={{ fontWeight: 700 }}>
            {t("cabinet.new_company.title")}
          </Typography>
        </Grid>
      </Grid>

      <Divider sx={{ mt: 2, mb: 6, width: "100%" }} />

      <Grid container rowSpacing={3}>
        <OwnerFields formikRef={ownerFormikRef} />
          <Divider sx={{ mt: 3, mb: 2, mx: "auto", width: "98%" }} />
        <CEOFields formikRef={ceoFormikRef} />
          <Divider sx={{ mt: 3, mb: 2, mx: "auto", width: "98%" }} />
        <CompanyFields formikRef={companyFormikRef} />
          <Divider sx={{ mt: 3, mb: 2, mx: "auto", width: "98%" }} />
        <ContactsFields formikRef={contactsFormikRef} />
          <Divider sx={{ mt: 3, mb: 2, mx: "auto", width: "98%" }} />
        <OtherFields formikRef={otherFormikRef} />
      </Grid>

      <Divider sx={{ mt: 6, mb: 2, width: "100%" }} />

      <Grid item container spacing={2} justifyContent="center" sx={{ pt: 2 }}>
        <Grid item xs="auto">
          <Button fullWidth variant="contained" size="large"
                  onClick={onClose} color="secondary"
          >
            {t("cabinet.new_company.cancel")}
          </Button>
        </Grid>
        <Grid item xs="auto">
          <Button fullWidth variant="contained" color="primary"
                  onClick={submit} size="large">
            {t("cabinet.new_company.add_company")}
          </Button>
        </Grid>
      </Grid>
		</Paper>
  )
}