import React from "react";
import { Autocomplete, Grid, TextField, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Field, FieldProps, Formik, FormikProps } from "formik";
import { initialValues, ValidationSchema } from "./formik";
import { NewAuctionContestForm } from "../../../../../types/newAuction";
import { AuctionCategory, AuctionType, BidStep, BidDirection } from "../../../../../utils/enums";
import { useDictionary } from "../../../../../utils/useDictionary";

export const ContestFields = ({
  formikRef, currentAuctionType, editingInitialState
}: {
  formikRef: React.RefObject<FormikProps<NewAuctionContestForm>>;
  currentAuctionType: AuctionType;
  editingInitialState?: NewAuctionContestForm
}) => {
  const onSubmit = (values: NewAuctionContestForm) => {};
  const { t } = useTranslation();

  const auctionCategory = useDictionary(
    AuctionCategory, 
    (type) => ({value: type, label: t(`auction_category.${type}`)}),
    editingInitialState 
      ? editingInitialState.auctionCategory
      : { value: AuctionCategory.sale, label: t("auction_category.sale") }
  );
  const auctionType = useDictionary(
    AuctionType, 
    (type) => ({value: type, label: t(`auction_type.${type}`)}),
    editingInitialState 
      ? editingInitialState.auctionType
      : { value: currentAuctionType, label: t(`auction_type.${currentAuctionType}`) }
  );
  const bidStep = useDictionary(
    BidStep, 
    (type) => ({value: type, label: t(`bid_step.${type}`)}),
    editingInitialState 
      ? editingInitialState.bidStep
      : { value: BidStep.step_1, label: t("bid_step.step_1") }
  );
  const bidDirection = useDictionary(
    BidDirection, 
    (type) => ({value: type, label: t(`bid_direction.${type}`)}),
    editingInitialState 
      ? editingInitialState.bidDirection
      : { value: BidDirection.increase, label: t("bid_direction.increase") }
  );

  {/* formik dict values */}
    React.useEffect(() => {
      formikRef.current?.setFieldValue(
        "auctionCategory", auctionCategory.selected, false);
    }, [auctionCategory.selected]);
    React.useEffect(() => {
      formikRef.current?.setFieldValue(
        "auctionType", auctionType.selected, false);
    }, [auctionType.selected]);
    React.useEffect(() => {
      formikRef.current?.setFieldValue(
        "bidStep", bidStep.selected, false);
    }, [bidStep.selected]);
    React.useEffect(() => {
      formikRef.current?.setFieldValue(
        "bidDirection", bidDirection.selected, false);
    }, [bidDirection.selected]);
  {/* / formik dict values */}

  return (
    <Formik innerRef={formikRef}
      initialValues={editingInitialState || initialValues} 
      validationSchema={ValidationSchema()}
      onSubmit={onSubmit}
      validateOnMount
    >
      {(frops: FormikProps<NewAuctionContestForm>) => (
        <Grid container columnSpacing={3} rowSpacing={1}>
          <Grid item xs={12} sx={{ mb: 1 }}>
            <Typography variant="h6">
              {t("auction.sections.contest")}
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6} md={8}>
            <Field name={"title"}>
              {({ field, form, meta }: FieldProps) => (
                <TextField {...field}
                  fullWidth color="primary" type="text" size="small"
                  label={t("auction.fields.auctionTitle")}
                  error={(meta.touched && meta.error) ? true : false}
                  helperText={meta.touched && meta.error}
                />
              )}
            </Field>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Autocomplete
              disablePortal size="small"
              options={auctionType.dictionary}
              isOptionEqualToValue={(option, value) => option.value === value.value}
              value={auctionType.selected} 
              onChange={auctionType.onSelectChange}
              renderInput={params => 
                <Field name={"auctionType"}>
                  {({ field, form, meta }: FieldProps) => (
                    <TextField {...params} fullWidth color="primary" 
                              label={t("auction.dictionary.auctionType")}
                              InputProps={{ readOnly: true }}
                              error={(meta.touched && meta.error) ? true : false}
                              helperText={meta.touched && meta.error}
                    /> 
                  )}
                </Field>
              } 
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12} sx={{ mt: 2 }}>
            <Field name={"description"}>
              {({ field, form, meta }: FieldProps) => (
                <TextField {...field}
                  multiline rows={4} fullWidth color="primary" type="text" size="small"
                  label={t("auction.fields.auctionDescription")}
                  error={(meta.touched && meta.error) ? true : false}
                  helperText={meta.touched && meta.error}
                />
              )}
            </Field>
          </Grid>

          {currentAuctionType === AuctionType.auction
            ? <>
                <Grid item container columnSpacing={3} rowSpacing={1} sx={{ mt: 2 }}>
                  <Grid item xs={12} sm={6} md={4}>
                    <Autocomplete
                      disablePortal size="small"
                      options={auctionCategory.dictionary}
                      isOptionEqualToValue={(option, value) => option.value === value.value}
                      value={auctionCategory.selected} 
                      onChange={auctionCategory.onSelectChange}
                      renderInput={params => 
                        <Field name={"auctionCategory"}>
                          {({ field, form, meta }: FieldProps) => (
                            <TextField {...params} fullWidth color="primary" 
                                      label={t("auction.dictionary.auctionCategory")}
                                      error={(meta.touched && meta.error) ? true : false}
                                      helperText={meta.touched && meta.error}
                            /> 
                          )}
                        </Field>
                      } 
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <Autocomplete
                      disablePortal size="small"
                      options={bidStep.dictionary}
                      isOptionEqualToValue={(option, value) => option.value === value.value}
                      value={bidStep.selected} 
                      onChange={bidStep.onSelectChange}
                      renderInput={params => 
                        <Field name={"bidStep"}>
                          {({ field, form, meta }: FieldProps) => (
                            <TextField {...params} fullWidth color="primary" 
                                      label={t("auction.dictionary.bidStep")}
                                      error={(meta.touched && meta.error) ? true : false}
                                      helperText={meta.touched && meta.error}
                            /> 
                          )}
                        </Field>
                      } 
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <Autocomplete
                      disablePortal size="small"
                      options={bidDirection.dictionary}
                      isOptionEqualToValue={(option, value) => option.value === value.value}
                      value={bidDirection.selected} 
                      onChange={bidDirection.onSelectChange}
                      renderInput={params =>
                        <Field name={"bidDirection"}>
                          {({ field, form, meta }: FieldProps) => (
                            <TextField {...params} fullWidth color="primary" 
                                      label={t("auction.dictionary.bidDirection")}
                                      error={(meta.touched && meta.error) ? true : false}
                                      helperText={meta.touched && meta.error}
                            />
                          )}
                        </Field>
                      }
                    />
                  </Grid>
                </Grid>
              </>
            : null
          }
        </Grid>
      )}
    </Formik>
  )
}
