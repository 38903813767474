import { Grid, Typography, TextField } from "@mui/material";
import { FormikProps, Formik, Field, FieldProps } from "formik";
import { RefObject } from "react";
import { useTranslation } from "react-i18next";
import { PhoneMaskInputComponent } from "../../../../../common/NumberFormat";
import { NewCompanyContactsForm } from "../../../../../types/newCompany";
import { initialValues, ValidationSchema } from "./formik";

export function ContactsFields({
  formikRef
}: {
  formikRef: RefObject<FormikProps<NewCompanyContactsForm>>;
}) {
  const onSubmit = (values: NewCompanyContactsForm) => {};
  const { t } = useTranslation();

  return (
    <Formik innerRef={formikRef}
      initialValues={initialValues}
      validationSchema={ValidationSchema()}
      onSubmit={onSubmit}
      validateOnMount
    >
      {(frops: FormikProps<NewCompanyContactsForm>) => (
        <>
          <Grid container columnSpacing={3} rowSpacing={1}>
            <Grid item xs={12} sx={{ mb: 1 }}>
              <Typography variant="h6">
                {t("cabinet.company_details.contacts")}
              </Typography>
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <Field name={"legalAddress"}>
                {({ field, form, meta }: FieldProps) => (
                  <TextField {...field}
                    fullWidth color="primary" type="text" size="small"
                    label={t("auction.fields.company.legal_address")}
                    error={(meta.touched && meta.error) ? true : false}
                    helperText={meta.touched && meta.error}
                  />
                )}
              </Field>
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <Field name={"actualAddress"}>
                {({ field, form, meta }: FieldProps) => (
                  <TextField {...field}
                    fullWidth color="primary" type="text" size="small"
                    label={t("auction.fields.company.actual_address")}
                    error={(meta.touched && meta.error) ? true : false}
                    helperText={meta.touched && meta.error}
                  />
                )}
              </Field>
            </Grid>
          </Grid>

          <Grid container columnSpacing={3} rowSpacing={1} sx={{ mt: 1 }}>
            <Grid item xs={12} sm={6} md={4}>
              <Field name={"phone"}>
                {({ field, form, meta }: FieldProps) => (
                  <TextField {...field}
                    fullWidth color="primary" type="text" size="small"
                    label={t("auction.fields.company.phone")}
                    error={(meta.touched && meta.error) ? true : false}
                    helperText={meta.touched && meta.error}
                    InputProps={{ inputComponent: PhoneMaskInputComponent }}
                  />
                )}
              </Field>
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <Field name={"email"}>
                {({ field, form, meta }: FieldProps) => (
                  <TextField {...field}
                    fullWidth color="primary" type="text" size="small"
                    label={t("auction.fields.company.email")}
                    error={(meta.touched && meta.error) ? true : false}
                    helperText={meta.touched && meta.error}
                  />
                )}
              </Field>
            </Grid>
          </Grid>
        </>
      )}
    </Formik>
  )
} 