import React, {useState} from 'react';
import {Routes as Switch, Route} from 'react-router-dom';
import WithLayout from './WithLayout';

// Available layouts
import {
    Main as MainLayout,
    Cabinet as CabinetLayout,
} from './layouts';

// Landing pages
import {
    Home as HomeView,
    Auctions as PublicAuctionsView,
    Auction as PublicAuctionView,
    Announcements as AnnouncementsView,
} from './views/landingPages';

// Supporting pages
import {
    NotFound as NotFoundView,
    Privacy as PrivacyView,
    Contact as ContactView,
    Documentation as DocumentationView,
    NoCompanies as NoCompaniesView,
} from './views/supportingPages';

// Authentication pages
import {
    LoginSimple as LoginSimpleView,
    SignupSimple as SignupSimpleView,
    SignupSimpleV2 as SignupSimpleViewV2,
    ForgotPasswordSimple as ForgotPasswordSimpleView,
} from './views/authPages';

// Account pages
import {
    Introduction as IntroductionView,
    Auctions as AuctionsView,
    Auction as AuctionView,
    NewAuction as NewAuctionView,
    Files as FilesView,
    Company as CompanyView,
    Employees as EmployeesView,
} from './views/account';

// Moderator pages
import {
    Introduction as ModeratorIntroductionView,
    Auctions as ModeratorAuctionsView,
    Auction as ModeratorAuctionView,
    Companies as ModeratorCompaniesView,
    Company as ModeratorCompanyView,
} from './views/moderator';

// Admin pages
import {
    Introduction as AdminIntroductionView,
    Companies as AdminCompaniesView,
    Company as AdminCompanyView,
    NewCompany as AdminNewCompanyView,
    Users as AdminUsersView,
    Announcements as AdminAnnouncementsView,
    NewAnnouncement as AdminNewAnnouncementView,
    Announcement as AdminAnnouncementView
} from './views/admin';

import {AuctionType, Authority} from './utils/enums';
import {useAuth} from './utils/useAuth';
import {useSelector} from "react-redux";
import {State} from "./redux/store";
import {Company} from "./types/company";

const Routes = () => {
    const {user} = useAuth();
    const [auctionEditing, setAuctionEditing] = useState(false);
    const [announcementEditing, setAnnouncementEditing] = useState(false);
    const company: Company | null = useSelector((state: State) => state.company.company)
    return (
        <Switch>
            {/* PUBLIC PAGES */}
            <Route
                path="/"
                element={
                    <WithLayout
                        component={HomeView}
                        layout={MainLayout}
                    />
                }
            />
            <Route
                path="/page-login"
                element={
                    <WithLayout
                        component={LoginSimpleView}
                        layout={MainLayout}
                    />
                }
            />
            <Route
                path="/page-signup"
                element={
                    <WithLayout
                        component={SignupSimpleView}
                        layout={MainLayout}
                    />
                }
            />
            <Route
                path="/page-signup-v2"
                element={
                    <WithLayout
                        component={SignupSimpleViewV2}
                        layout={MainLayout}
                    />
                }
            />
            <Route
                path="/page-forgot-password"
                element={
                    <WithLayout
                        component={ForgotPasswordSimpleView}
                        layout={MainLayout}
                    />
                }
            />
            <Route
                path="/page-auctions"
                element={
                    <WithLayout
                        component={PublicAuctionsView}
                        layout={MainLayout}
                    />
                }
            />
            <Route
                path="/page-auctions/:auctionNo"
                element={
                    <WithLayout
                        component={PublicAuctionView}
                        layout={MainLayout}
                    />
                }
            />
            {/* / PUBLIC PAGES */}

            {/* ADMIN PAGES */}
            <Route
                path="/admin-introduction"
                element={
                    <WithLayout
                        component={AdminIntroductionView}
                        layout={CabinetLayout}
                        role={Authority.ADMIN}
                    />
                }
            />
            <Route
                path="/admin-companies"
                element={
                    <WithLayout
                        component={AdminCompaniesView}
                        layout={CabinetLayout}
                        role={Authority.ADMIN}
                    />
                }
            />
            <Route
                path="/admin-companies/:id"
                element={
                    <WithLayout
                        component={AdminCompanyView}
                        layout={CabinetLayout}
                        role={Authority.ADMIN}
                    />
                }
            />
            <Route
                path="/admin-companies/create"
                element={
                    <WithLayout
                        component={AdminNewCompanyView}
                        layout={CabinetLayout}
                        role={Authority.ADMIN}
                    />
                }
            />
            <Route
                path="/admin-users"
                element={
                    <WithLayout
                        component={AdminUsersView}
                        layout={CabinetLayout}
                        role={Authority.ADMIN}
                    />
                }
            />
            <Route
                path="/admin-announcements"
                element={
                    <WithLayout
                        component={AdminAnnouncementsView}
                        layout={CabinetLayout}
                        role={Authority.ADMIN}
                    />
                }
            />
            <Route
                path="/admin-announcements/create"
                element={
                    <WithLayout
                        component={AdminNewAnnouncementView}
                        layout={CabinetLayout}
                        role={Authority.ADMIN}
                    />
                }
            />
            <Route
                path="/admin-announcements/:id"
                element={
                    <WithLayout
                        component={() => announcementEditing
                            ? <AdminNewAnnouncementView
                                announcementEditing={announcementEditing}
                                setAnnouncementEditing={setAnnouncementEditing}
                            />
                            : <AdminAnnouncementView
                                announcementEditing={announcementEditing}
                                setAnnouncementEditing={setAnnouncementEditing}
                            />
                        }
                        layout={CabinetLayout}
                        role={Authority.ADMIN}
                    />
                }
            />
            {/* / ADMIN PAGES */}

            {/* MODERATOR PAGES */}
            <Route
                path="/moderator-introduction"
                element={
                    <WithLayout
                        component={ModeratorIntroductionView}
                        layout={CabinetLayout}
                        role={Authority.MODERATOR}
                    />
                }
            />
            <Route
                path="/moderator-auctions"
                element={
                    <WithLayout
                        component={ModeratorAuctionsView}
                        layout={CabinetLayout}
                        role={Authority.MODERATOR}
                    />
                }
            />
            <Route
                path="/moderator-auctions/:id"
                element={
                    <WithLayout
                        component={ModeratorAuctionView}
                        layout={CabinetLayout}
                        role={Authority.MODERATOR}
                    />
                }
            />
            <Route
                path="/moderator-companies"
                element={
                    <WithLayout
                        component={ModeratorCompaniesView}
                        layout={CabinetLayout}
                        role={Authority.MODERATOR}
                    />
                }
            />
            <Route
                path="/moderator-companies/:id"
                element={
                    <WithLayout
                        component={ModeratorCompanyView}
                        layout={CabinetLayout}
                        role={Authority.MODERATOR}
                    />
                }
            />
            {/* / MODERATOR PAGES */}

            {/* ACCOUNT PAGES */}
            <Route
                path="/account-introduction"
                element={
                    <WithLayout
                        component={IntroductionView}
                        layout={CabinetLayout}
                    />
                }
            />
            <Route
                path="/account-tender"
                element={
                    <WithLayout
                        component={() =>
                            <AuctionsView
                                title="Тендер"
                                auctionsEndpoint="owned"
                                auctionType={AuctionType.tender}
                                auctionPath="tender"
                            />
                        }
                        layout={CabinetLayout}
                    />
                }
            />
            <Route
                path="/account-tender/:id"
                element={
                    <WithLayout
                        component={() => auctionEditing
                            ? <NewAuctionView
                                title="Редактирование конкурса по тендеру" // №
                                auctionType={AuctionType.tender}
                                auctionPath="tender"
                                auctionEditing={auctionEditing}
                                setAuctionEditing={setAuctionEditing}
                            />
                            : <AuctionView
                                auctionType={AuctionType.tender}
                                auctionPath="tender"
                                setAuctionEditing={setAuctionEditing}
                            />
                        }
                        layout={CabinetLayout}
                    />
                }
            />
            <Route
                path="/account-tender/create"
                element={
                    <WithLayout
                        component={() =>
                            <NewAuctionView
                                title="Создание конкура по тендеру"
                                auctionType={AuctionType.tender}
                                auctionPath="tender"
                            />
                        }
                        layout={CabinetLayout}
                    />
                }
            />
            <Route
                path="/account-price-offer"
                element={
                    <WithLayout
                        component={() =>
                            <AuctionsView
                                title="Ценовые предложения"
                                auctionsEndpoint="owned"
                                auctionType={AuctionType.price_offer}
                                auctionPath="price-offer"
                            />
                        }
                        layout={CabinetLayout}
                    />
                }
            />
            <Route
                path="/account-price-offer/:id"
                element={
                    <WithLayout
                        component={() => auctionEditing
                            ? <NewAuctionView
                                title="Редактирование конкурса по ценовым предложениям" // №
                                auctionType={AuctionType.price_offer}
                                auctionPath="price-offer"
                                auctionEditing={auctionEditing}
                                setAuctionEditing={setAuctionEditing}
                            />
                            : <AuctionView
                                auctionType={AuctionType.price_offer}
                                auctionPath="price-offer"
                                setAuctionEditing={setAuctionEditing}
                            />
                        }
                        layout={CabinetLayout}
                    />
                }
            />
            <Route
                path="/account-price-offer/create"
                element={
                    <WithLayout
                        component={() =>
                            <NewAuctionView
                                title="Создание конкурса по ценовым предложениям"
                                auctionType={AuctionType.price_offer}
                                auctionPath="price-offer"
                            />
                        }
                        layout={CabinetLayout}
                    />
                }
            />
            <Route
                path="/account-single-source"
                element={
                    <WithLayout
                        component={() =>
                            <AuctionsView
                                title="Закуп из одного источника"
                                auctionsEndpoint="owned"
                                auctionType={AuctionType.single_source}
                                auctionPath="single-source"
                            />
                        }
                        layout={CabinetLayout}
                    />
                }
            />
            <Route
                path="/account-single-source/:id"
                element={
                    <WithLayout
                        component={() => auctionEditing
                            ? <NewAuctionView
                                title="Редактирование конкурса по закупу из одного источника" // №
                                auctionType={AuctionType.single_source}
                                auctionPath="single-source"
                                auctionEditing={auctionEditing}
                                setAuctionEditing={setAuctionEditing}
                            />
                            : <AuctionView
                                auctionType={AuctionType.single_source}
                                auctionPath="single-source"
                                setAuctionEditing={setAuctionEditing}
                            />
                        }
                        layout={CabinetLayout}
                    />
                }
            />
            <Route
                path="/account-single-source/create"
                element={
                    <WithLayout
                        component={() =>
                            <NewAuctionView
                                title="Создание конкурса по закупу из одного источника"
                                auctionType={AuctionType.single_source}
                                auctionPath="single-source"
                            />
                        }
                        layout={CabinetLayout}
                    />
                }
            />
            <Route
                path="/account-auction"
                element={
                    <WithLayout
                        component={() =>
                            <AuctionsView
                                title="Аукцион стандартный"
                                auctionsEndpoint="owned"
                                auctionType={AuctionType.auction}
                                auctionPath="auction"
                            />
                        }
                        layout={CabinetLayout}
                    />
                }
            />
            <Route
                path="/account-auction/:id"
                element={
                    <WithLayout
                        component={() => auctionEditing
                            ? <NewAuctionView
                                title="Редактирование аукциона" // №
                                auctionType={AuctionType.auction}
                                auctionPath="auction"
                                auctionEditing={auctionEditing}
                                setAuctionEditing={setAuctionEditing}
                            />
                            : <AuctionView
                                auctionType={AuctionType.auction}
                                auctionPath="auction"
                                setAuctionEditing={setAuctionEditing}
                            />
                        }
                        layout={CabinetLayout}
                    />
                }
            />
            <Route
                path="/account-auction/create"
                element={
                    <WithLayout
                        component={() =>
                            <NewAuctionView
                                title="Создание аукциона стандартного"
                                auctionType={AuctionType.auction}
                                auctionPath="auction"
                            />
                        }
                        layout={CabinetLayout}
                    />
                }
            />
            <Route
                path="/account-auctions-actual"
                element={
                    <WithLayout
                        component={() =>
                            <AuctionsView
                                title="Реестр конкурсов"
                                auctionPath="auctions-actual"
                                auctionsEndpoint="actual"
                            />
                        }
                        layout={CabinetLayout}
                    />
                }
            />
            <Route
                path="/account-auctions-actual/:id"
                element={
                    <WithLayout
                        component={() =>
                            <AuctionView
                                auctionPath="auctions-actual"
                            />
                        }
                        layout={CabinetLayout}
                    />
                }
            />
            <Route
                path="/account-auctions-participated"
                element={
                    <WithLayout
                        component={() =>
                            <AuctionsView
                                title="Мои заявки"
                                auctionPath="auctions-participated"
                                auctionsEndpoint="participated"
                            />
                        }
                        layout={CabinetLayout}
                    />
                }
            />
            <Route
                path="/account-auctions-participated/:id"
                element={
                    <WithLayout
                        component={() =>
                            <AuctionView
                                auctionPath="auctions-participated"
                            />
                        }
                        layout={CabinetLayout}
                    />
                }
            />
            <Route
                path="/account-files"
                element={
                    <WithLayout
                        component={FilesView}
                        layout={CabinetLayout}
                    />
                }
            />
            <Route
                path="/account-company-profile"
                element={
                    <WithLayout
                        component={CompanyView}
                        layout={CabinetLayout}
                    />
                }
            />
            <Route
                path="/account-employees"
                element={
                    <WithLayout
                        component={EmployeesView}
                        layout={CabinetLayout}
                    />
                }
            />
            {/* / ACCOUNT PAGES */}

            {/* SUPPORTING PAGES */}
            <Route
                path="/page-privacy"
                element={
                    <WithLayout
                        component={PrivacyView}
                        layout={MainLayout}
                    />
                }
            />
            <Route
                path="/page-contacts"
                element={
                    <WithLayout
                        component={ContactView}
                        layout={MainLayout}
                    />
                }
            />
            <Route
                path="/page-documentation"
                element={
                    <WithLayout
                        component={DocumentationView}
                        layout={MainLayout}
                    />
                }
            />
            <Route
                path="/page-announcements"
                element={
                    <WithLayout
                        component={AnnouncementsView}
                        layout={MainLayout}
                    />
                }
            />

            <Route
                path="/page-no-companies"
                element={
                    <WithLayout
                        component={NoCompaniesView}
                        layout={MainLayout}
                    />
                }
            />
            {/* / SUPPORTING PAGES */}

            <Route
                path='*'
                element={
                    <WithLayout
                        component={NotFoundView}
                        layout={MainLayout}
                    />
                }
            />
        </Switch>
    );
};

export default Routes;
