import React, { useEffect, useState } from 'react';
import { Box, Button, Grid, Tooltip, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useDispatch, useSelector } from 'react-redux';
import { companyService } from '../../../api/services/company';
import { SET_EMPLOYEES } from '../../../redux/reducers/company';
import { Company, Employee } from '../../../types/company';
import { useSnack } from '../../../utils/useSnack';
import { State } from '../../../redux/store';
import Container from '../../../common/Container';
import { useTranslation } from 'react-i18next';
import { NewEmployeeModal } from './components';
import NoRowsOverlay from '../../../common/NoRowsOverlay';
import { Column, columnStyle } from "../../../common/Columns";

export default function Employees() {
  	const { t } = useTranslation();
	const { snack } = useSnack();
	const state = useSelector((state: State) => state);
	const dispatch = useDispatch();

	const [loading, setLoading] = useState(false);
	const company: Company | null = state.company.company;
	const employees: Employee[] = state.company.employees;

	const getEmployees = async () => {
		if (!company) return;
		
			try {
				setLoading(true);
			const response = await companyService.getEmployees(company.id);

				if (response.status === "success" && response.data) {
					dispatch({
						type: SET_EMPLOYEES,
						payload: response.data
					});
				}
				else {
					snack(t("snack.error.employees"), "error");
				}
		}
		catch (err) { 
			snack(t("snack.error.employees"), "error");
		}
		finally { setLoading(false) }
	};

	const [isNewOpen, setIsNewOpen] = React.useState(false);
	const onOpenNew = () => setIsNewOpen(true);
	const onCloseNew = () => setIsNewOpen(false);

	useEffect(() => {if (company?.id) getEmployees()}, [company]);

	return (
		<Box>
      <NewEmployeeModal isOpen={isNewOpen} onClose={onCloseNew} />

      <Container paddingY={{ xs: 2, sm: 4 }} maxWidth="unset">
			<Box marginBottom={4}>
				<Typography
					variant="h3"
					gutterBottom
					sx={{
						fontWeight: 700,
					}}
				>
					{t("cabinet.employees.title")}
				</Typography>
			</Box>

				<Grid item container spacing={2} alignItems={"center"} mb={2}>
					<Grid item xs={12} sm={5} md={4} lg={3}>
						<Button fullWidth color="primary" variant="contained"
							onClick={onOpenNew}>
							<span style={{ whiteSpace: 'nowrap' }}>
								{t("cabinet.employees.add_new_employee")}
							</span>
						</Button>
					</Grid>
				</Grid>

				<Box>
					<DataGrid 
						components={{ NoRowsOverlay: () => <NoRowsOverlay /> }}
						sx={{
							height: 500,
							"& .MuiDataGrid-cell": {
								whiteSpace: "pre-wrap"
							},
						}}
						columnBuffer={4}
						rows={employees.length 
							? employees.map(employee => ({ ...employee, id: employee.userId})) 
							: []
						} 
						columns={[ 
							Column("role", 150, columnStyle, "role"),
							Column("username", 350, columnStyle),
							Column("name", 350, columnStyle),
							Column("identity", 200, columnStyle)
						]}
						loading={loading}
						density="comfortable" style={{ whiteSpace: "pre-wrap" }}
					/>
				</Box>
			</Container>
		</Box>
	);
};
