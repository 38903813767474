export enum State {
	active,
	closed
}

export enum Authority {
	USER = "USER",
	ADMIN = "ADMIN",
	MODERATOR = "MODERATOR"
}

export enum CompanyState {
	active = 'active',
	forbidden = 'forbidden'
}

export enum CompanySize {
	small = 'small',
	middle = 'middle',
	large = 'large'
}

export enum CompanyForm {
	kz_too = 'kz_too',
	kz_tdo = 'kz_tdo',
	kz_pt = 'kz_pt',
	kz_kt = 'kz_kt',
	kz_pk = 'kz_pk',
	kz_ao = 'kz_ao',
	kz_ip = 'kz_ip'
}

export enum CompanyOwnership {
	private_owner = 'private_owner',
	state_owner = 'state_owner'
}

export enum Country {
	kz = 'kz',
	ru = 'ru',
	by = 'by',
	ua = 'ua'
}

export enum ProfileRole {
	owner = 'owner',
	employee = 'employee'
}

export enum FileCategory {
	agreement = "agreement",
	license = "license",
	other = "other"
}

export enum FileState {
	active = "active",
	archived = "archived"
}

export enum AuctionType {
	tender = "tender",     // Тендер
	auction = "auction",    // Аукцион
	price_offer = "price_offer", // Ценовое предложение
	single_source = "single_source" // Закуп из одного источника
}

export enum Unit {
	sht = "sht",
	upak = "upak",
	usluga = "usluga",
	ch = "ch",
	kg = "kg",
	t = "t",
	m3 = "m3",
	km = "km",
	m = "m",
	mp = "mp",
	m2 = "m2",
	ga = "ga",
	km2 = "km2",
	work = "work",
	G = "G",
	L = "L"
}

export enum BidStep {
	step_1 = "step_1",
	step_3 = "step_3",
	step_5 = "step_5"
}

export enum BidDirection {
	increase = "increase",
	decrease = "decrease"
}

export enum AuctionState {
	draft = "draft",
	ready = "ready",
	executed = "executed",
	review = "review",
	published = "published",
	registration = "registration",
	approvement = "approvement",
	opened = "opened",
	closed = "closed",
	canceled = "canceled",
	finished = "finished",
	issued = "issued",
	terminated = "terminated",
}

export enum ParticipantState {
	registered = "registered",
	approved = "approved",
	declined = "declined",
}

export enum AuctionCancelReason {
	not_enough_participants = "not_enough_participants",
	not_enough_bids = "not_enough_bids"
}

export enum DeliveryRegion {
	// "kz1900" = "kz1900",
	"kz1971" = "kz1971",
	"kz1975" = "kz1975",
	"kz1979" = "kz1979",
	"kz1911" = "kz1911",
	"kz1915" = "kz1915",
	"kz1919" = "kz1919",
	"kz1923" = "kz1923",
	"kz1927" = "kz1927",
	"kz1931" = "kz1931",
	"kz1935" = "kz1935",
	"kz1939" = "kz1939",
	"kz1943" = "kz1943",
	"kz1947" = "kz1947",
	"kz1951" = "kz1951",
	"kz1955" = "kz1955",
	"kz1959" = "kz1959",
	"kz1963" = "kz1963"
}

export enum ProductType {
	product = "product",
	service = "service",
	work = "work",
	custom = "custom"
}

export enum ReviewResult {
	accepted = "accepted",
	declined = "declined"
}

export enum AuctionCategory {
	sale = "sale",
	purchase = "purchase"
}

export enum StandardReviewReason {
	rule_violated = "rule_violated",
	law_violated = "law_violated"
}

export enum StorageType {
	PKCS12 = "PKCS12",
	AKKaztokenStore = "AKKaztokenStore",
	AKEToken72KStore = "AKEToken72KStore"
}

export enum DeliveryCondition {
	exw = "exw", // EXW Ex Works / Франко завод
	fca = "fca", // FCA Free Carrier / Франко перевозчик
	cpt = "cpt", // CPT Carriage Paid to / Перевозка оплачена до
	cip = "cip", // CIP Carriage and Insurance Paidto / Перевозка и страхование оплачены до
	dat = "dat", // DAT Delivered at Terminal / Поставка на терминале
	dap = "dap", // DAP Delivered at Place / Поставка в месте назначения
	ddp = "ddp", // DDP Delivered Duty Paid / Поставка с оплатой пошлин
	fas = "fas", // FAS Free Along side Ship / Свободно вдоль борта судна
	fob = "fob", // FOB Free on Board / Свободно на борту
	cfr = "cfr", // CFR Cost and Freight / Стоимость и фрахт
	cif = "cif"  // CIF Cost Insurance and Freight / Стоимость, страхование и фрахт
}
