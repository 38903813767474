import { Grid, Typography, TextField, Autocomplete } from "@mui/material";
import { Field, FieldProps, Formik, FormikProps } from "formik";
import { RefObject, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { adminService } from "../../../../../api/services/admin";
import { User } from "../../../../../types/auth";
import { NewCompanyOwnerForm } from "../../../../../types/newCompany";
import { useSnack } from "../../../../../utils/useSnack";
import { initialValues, ValidationSchema } from "./formik";

export function OwnerFields({
  formikRef
}: {
  formikRef: RefObject<FormikProps<NewCompanyOwnerForm>>;
}) {
  const onSubmit = (values: NewCompanyOwnerForm) => {};
  const { snack } = useSnack();
  const { t } = useTranslation();
  const [users, setUsers] = useState<User[]>([]);

  const onSelectChange = (
    e: React.SyntheticEvent<Element, Event>,
    newValue: User | null,
  ) => {
    if (newValue) formikRef.current?.setFieldValue("ownerUser", newValue);
  };

  async function getRegisteredUsers() {
    try {
      const response = await adminService.getUsers();
      if (response.status === "success" && response.data) {
        setUsers(response.data.data);
      }
      else { snack(t("snack.error.users"), "error") }
    }
    catch (err) { snack(t("snack.error.users"), "error") }
  }

  useEffect(() => { getRegisteredUsers() }, []);

  return (
    <Formik innerRef={formikRef}
      initialValues={initialValues}
      validationSchema={ValidationSchema()}
      onSubmit={onSubmit}
      validateOnMount
    >
      {(frops: FormikProps<NewCompanyOwnerForm>) => (
        <Grid container columnSpacing={3} rowSpacing={1}>
          <Grid item xs={12} sx={{ mb: 1 }}>
            <Typography variant="h6">
              {t("cabinet.company_details.owner")}
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Autocomplete
              disablePortal size="small"
              options={users}
              isOptionEqualToValue={(option, value) => option.username === value.username}
              getOptionLabel={option => option.username}
              value={frops.values.ownerUser} 
              onChange={onSelectChange}
              renderInput={params => 
                <Field name={"ownerUser"}>
                  {({ field, form, meta }: FieldProps) => (
                    <TextField {...params} fullWidth color="primary" 
                      label={t("auction.fields.company.owner_email")}
                      // error={(meta.touched && meta.error) ? true : false}
                      // helperText={meta.touched && meta.error}
                    /> 
                  )}
                </Field>
              }
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Field name={"ownerIdentity"}>
              {({ field, form, meta }: FieldProps) => (
                <TextField {...field}
                  fullWidth color="primary" type="text" size="small"
                  label={t("auction.fields.company.owner_iin")}
                  error={(meta.touched && meta.error) ? true : false}
                  helperText={meta.touched && meta.error}
                />
              )}
            </Field>
          </Grid>
        </Grid>
      )}
    </Formik>
  )
}