import {t} from "i18next";
import * as Yup from "yup";
import {NewCompanyCompanyForm} from "../../../../../types/newCompany";

export const initialValues: NewCompanyCompanyForm = {
    itn: "",
    title: "",
    shortTitle: "",
    legalForm: {value: "", label: ""},
    size: {value: "", label: ""},
    ownershipForm: {value: "", label: ""}
};

export function ValidationSchema() {
    return Yup.object({
        itn: Yup.string()
            .matches(/^[0-9]*$/, t("validation.incorrect"))
            .min(12, t("validation.incorrect"))
            .max(12, t("validation.incorrect"))
            .required(t("validation.required")),
        title: Yup.string().required(t("validation.required")),
        shortTitle: Yup.string().required(t("validation.required")),
        legalForm: Yup.object({
            value: Yup.string().required(t("validation.required")),
        }),
        size: Yup.object({
            value: Yup.string().required(t("validation.required")),
        }),
        ownershipForm: Yup.object({
            value: Yup.string().required(t("validation.required")),
        }),
    });
}
