import React, { useState } from "react";
import { Box, Grid, Typography, TextField, Chip, LinearProgress, Divider, 
  Button, Paper, Modal } from "@mui/material";
import { useTranslation } from "react-i18next";
import { AxiosError } from "axios";
import { useSelector } from "react-redux";
import CloseIcon from '@mui/icons-material/Close';
import { auctionService } from "../../../../../api/services/auction";
import { publicService } from "../../../../../api/services/public";
import { State } from "../../../../../redux/store";
import digitsOnly from "../../../../../utils/digitsOnly";
import { ParticipantState, AuctionState } from "../../../../../utils/enums";
import { Auction, AuctionFile } from "../../../../../types/auction";
import { Company } from "../../../../../types/company";
import { useSnack } from "../../../../../utils/useSnack";
import { auctionStateArray } from "../../../../../utils/auctionStateArray";
import { useNavigate } from "react-router-dom";
import { CancelModal, ReviewModal } from "./components";
import { companyService } from "../../../../../api/services/company";
import { switchStatusText } from "../../../../../utils/switchAuctionText";

export default function Participants({ 
  auctionPath, auctionId, auction, getAuction
}: { 
  auctionPath: string;
  auctionId: string;
  auction: Auction | null;
  getAuction: () => Promise<void>
}) {
  const { t } = useTranslation();
  const { snack } = useSnack();
  const navigate = useNavigate();
  const state = useSelector((state: State) => state);
  const company: Company | null = state.company.company;

  const [isRequesting, setIsRequesting] = useState(false);

  const [declineReason, setDeclineReason] = useState("");
  const [isDeclineReasonOpen, setIsDeclineReasonOpen] = useState(false);
  const onOpenDeclineReason = () => setIsDeclineReasonOpen(true);
  const onCloseDeclineReason = () => setIsDeclineReasonOpen(false);

  const [downloadingId, setDownloadingId] = React.useState("");
  const downloadFile = async (file: AuctionFile) => {
    if (!company) return;

    try {
      setDownloadingId(file.id);
      const fileTokenResponse = await auctionService.getFileToken(auctionId, company.id, file.id);
      if (fileTokenResponse.status === "success" && fileTokenResponse.data) {
        publicService.downloadFile(fileTokenResponse.data.id, file.title);
      }
      else { snack(t("snack.error.file_download"), "error") }
    }
    catch (error) {
      const err = error as AxiosError;
      snack(t("snack.error.file_download"), "error"); 
    }
    finally { setDownloadingId("") }
  };

  function accept(participantCompanyId: string) {
    changeParticipantStatus(participantCompanyId, ParticipantState.approved)
  }
  function decline(participantCompanyId: string) {
    changeParticipantStatus(participantCompanyId, ParticipantState.declined)
  }

  async function changeParticipantStatus(
    participantCompanyId: string, participantState: ParticipantState
  ) {
    if (!company) { return }
    const data = {
      companyId: participantCompanyId,
      state: participantState,
      declineReason
    }
    try {
      setIsRequesting(true);
      const response = await auctionService.changeParticipantStatus(company.id, auctionId, data);
      getAuction();
      onCloseDeclineReason();
      if (response.status === "success" && response.data) {
        snack(t("snack.success.participant_status_changed"), "success");
        return
      }
      snack(t("snack.error.participant_status_changed"), "error"); 
    }
    catch (error) {
      const err = error as AxiosError;
      snack(t("snack.error.participant_status_changed"), "error"); 
    }
    finally { setIsRequesting(false) }
  };

  function onClose() { navigate(`/account-${auctionPath}`) };
  const [isCancelOpen, setIsCancelOpen] = useState(false);
  function onCancelOpen() { setIsCancelOpen(true) };
  function onCancelClose() { setIsCancelOpen(false) };
  const [isReviewOpen, setIsReviewOpen] = useState(false);
  function onReviewOpen() { setIsReviewOpen(true) };
  function onReviewClose() { setIsReviewOpen(false) };

  async function downloadReviewFile() {
    if (!company || !auction?.participationReviewResultFileId) { return }

    try {
      const fileTokenResponse = await companyService.getFileToken(company.id, auction.participationReviewResultFileId);
      if (fileTokenResponse.status === "success" && fileTokenResponse.data) {
        publicService.downloadFile(fileTokenResponse.data.id, `${t("docs.entree_protocol")}.pdf`);
      }
      else { snack(t("snack.error.file_download"), "error") }
    }
    catch (error) {
      const err = error as AxiosError;
      snack(t("snack.error.default"), "error"); 
    }
    finally { setDownloadingId("") }
  }

  return (
    <Box>
      <CancelModal isOpen={isCancelOpen} onClose={onCancelClose} 
        auctionId={auctionId} onAuctionClose={onClose}
      />
      <ReviewModal isOpen={isReviewOpen} onClose={onReviewClose} 
        auctionId={auctionId} getAuction={getAuction}
      />

      <Grid item xs={12} sx={{ mb: 7 }}>
        <Typography fontSize={20} sx={{ mb: 1 }}>
          {auction 
            ? auctionStateArray("before", AuctionState.approvement).includes(auction.state)
              ? `${t("auction.fields.approvementOpenTime")}: ${auction?.registrationOpenTime.replace('T', " ")}`
              : `${t("auction.fields.approvementCloseTime")}: ${auction?.bidOpenTime.replace('T', " ")}`
            : "- - -"
          }
        </Typography>
        <Typography fontSize={20}>
          {switchStatusText(auction)}: {t(`auction_state.${auction?.state}`)}
        </Typography>
        {auction?.state === AuctionState.canceled
          ? <Typography fontSize={20} sx={{ mt: 1 }}>
              {t("auction.participants.cancel_reason")}: {t(`cancel_reasons.${auction?.cancelReason}`)}
            </Typography>
          : null
        }
      </Grid>
      {auction 
        ? auctionStateArray("andAfter", AuctionState.registration).includes(auction.state)
          ? isRequesting
            ? <Box>
                <LinearProgress sx={{ mt: 3, mb: 1 }} color="primary" />
                <Typography>{t("auction.participants.changing_status")}</Typography>
              </Box>
            : <Grid container rowGap={3}>
                {(auction?.participants && auction.participants.length)
                  ? auction.participants.map((participant, idx) => (
                      <Paper key={idx} sx={{ width: "100%", p: { xs: 2, sm: 3 } }} elevation={5}>
                        <Grid item xs={12} sx={{ mb: 3 }}>
                          <Typography variant="h6">{t("auction.participants.supplier")} {participant.company.shortTitle}</Typography>
                        </Grid>

                        <Grid container columnGap={3} rowGap={3}>
                          <Grid item key={idx} container spacing={3} xs={12}>
                            <Grid item xs={12} sm={6}>
                              <TextField
                                fullWidth color="primary" type="text" size="small"
                                label={t("auction.fields.companyTitle")}
                                value={participant.company.title}
                                InputProps={{ readOnly: true }}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <TextField
                                fullWidth color="primary" type="text" size="small"
                                label={t("auction.fields.companyItn")}
                                value={digitsOnly(participant.company.itn)}
                                InputProps={{ readOnly: true }}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <TextField
                                fullWidth color="primary" type="text" size="small"
                                label={t("auction.fields.registrationTime")}
                                value={participant.registrationTime.replace('T', " ")}
                                InputProps={{ readOnly: true }}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <TextField
                                fullWidth color="primary" type="text" size="small"
                                label={t("auction.fields.actualAddress")}
                                value={participant.company.actualAddress}
                                InputProps={{ readOnly: true }}
                              />
                            </Grid>
                          </Grid>

                          <Grid item xs={12} sm={4}>
                            <Typography><b>{t("auction.participants.attached_docs")}</b></Typography>

                            <Box display={"flex"} gap={2}>
                              {participant.attachments.length
                                ? participant.attachments.map(file => (
                                    <Grid item key={file.id}>
                                      <Typography fontSize={12} textAlign={"center"} mt={1} mb={.5}>
                                        {t(`file_category.${file.category}`)}
                                      </Typography>

                                      <Chip label={file.title}
                                        onClick={() => downloadFile(file)}
                                        disabled={downloadingId === file.id}
                                      />

                                      {downloadingId === file.id && <LinearProgress color="primary" />}
                                    </Grid>
                                  ))
                                : <Grid item>
                                    <Typography>{t("auction.participants.no_docs")}</Typography>
                                  </Grid>
                              }
                            </Box>
                          </Grid>
                        </Grid>

                        <Divider sx={{ mt: 3, ml: "auto", width: '100%' }} />

                        <Grid item container spacing={2} justifyContent="flex-end" sx={{ pt: 2 }}
                          alignItems="baseline"
                        >
                          {participant.state === ParticipantState.registered
                            ? <>
                                {auction.state === AuctionState.registration
                                  ? <Typography>{t("auction.participants.available_on_approvement")}</Typography>
                                  : null
                                }
                                <Grid item xs="auto">
                                  <Button fullWidth variant="contained" size="large"
                                          onClick={() => accept(participant.company.id)} color="primary"
                                          disabled={auction.state !== AuctionState.approvement}
                                  >
                                    {t("auction.participants.approve")}
                                  </Button>
                                </Grid>
                                <Grid item xs="auto">
                                  <Button fullWidth variant="contained" size="large"
                                          onClick={onOpenDeclineReason} color="error"
                                          disabled={auction.state !== AuctionState.approvement}
                                  >
                                    {t("auction.participants.decline")}
                                  </Button>
                                </Grid>
                              </>
                            : <Grid item xs="auto">
                                {participant.state === ParticipantState.approved
                                  ? <Typography color="primary">{t("auction.participants.approved")}</Typography>
                                  : <Typography color="error">{t("auction.participants.declined")}</Typography>
                                }
                              </Grid>
                          }

                          <Modal open={isDeclineReasonOpen} onClose={onCloseDeclineReason}>
                            <Box
                              sx={{ 
                                width: "100%", maxWidth: { xs: '300px', sm: '600px' }, 
                                margin: { xs: '10px', sm: '5px 20px' }, p: { xs: 2, sm: 3 },
                                bgcolor: 'background.paper', boxShadow: 24,
                                transform: 'translate(-50%, -50%)',
                                position: 'absolute', top: '50%', left: '47%',
                                transition: "height 1s"
                              }}
                            >
                              {/* header */}
                                <Grid container spacing={2} justifyContent="space-between" sx={{ mb: 2 }}>
                                  <Grid item>
                                    <Typography component="h5" variant="h5" sx={{ fontSize: { xs: 14, sm: 20 } }}>
                                      {t("auction.participants.please_confirm_decline")}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={2.2} sm={1.2}>
                                    <Button onClick={onCloseDeclineReason} sx={{ color: "black" }}>
                                      <CloseIcon />
                                    </Button>
                                  </Grid>
                                </Grid>
                              {/* / header */}

                              <Grid item>
                                <TextField
                                  fullWidth color="primary" type="text" size="small"
                                  name="declineReason" label={t("auction.fields.decline_reason")}
                                  value={declineReason}
                                  onChange={e => setDeclineReason(e.target.value)}
                                />
                              </Grid>

                              <Grid item display={"flex"} justifyContent={"flex-end"} mt={2}>
                                <Button variant="contained" size="small" sx={{ mr: 1 }}
                                        onClick={onCloseDeclineReason} color="secondary"
                                >
                                  {t("auction.modals.common.cancel")}
                                </Button>
                                <Button variant="contained" color="primary" size="small"
                                        onClick={() => decline(participant.company.id)} disabled={!declineReason}
                                >
                                  {t("auction.participants.confirm_decline")}
                                </Button>
                              </Grid>
                            </Box>
                          </Modal>
                        </Grid>
                      </Paper>
                    ))
                  : <Grid item xs={12} sx={{ mb: 3 }}>
                      <Typography variant="h6">{t("auction.participants.no_participants")}</Typography>
                    </Grid>
                }

                <Grid item container spacing={2} justifyContent="center" sx={{ pt: 2 }}>
                  <Grid item xs="auto">
                    <Button fullWidth variant="contained" size="large"
                            onClick={onClose} color="secondary"
                    >
                      {t("auction.close")}
                    </Button>
                  </Grid>

                  {auction.state === AuctionState.approvement
                    ? <>
                        <Grid item xs="auto">
                          <Button fullWidth variant="contained" size="large"
                                  onClick={onCancelOpen} color="error"
                          >
                            {t("auction.participants.cancel_auction")}
                          </Button>
                        </Grid>
                        <Grid item xs="auto">
                          <Button fullWidth variant="contained" size="large"
                                  onClick={onReviewOpen} color="primary" 
                                  disabled={auction.participationReviewResultFileId ? true : false}
                          >
                            {auction.participationReviewResultFileId
                              ? t("auction.participants.review_file_added")
                              : t("auction.participants.add_review_file")
                            }
                          </Button>
                        </Grid>
                      </>
                    : null
                  }

                  {auctionStateArray("after", AuctionState.approvement).includes(auction.state)
                    ? <Grid item xs="auto">
                        <Button fullWidth variant="contained" size="large"
                                onClick={downloadReviewFile} color="primary"
                                disabled={!auction.participationReviewResultFileId}
                        >
                          {auction.participationReviewResultFileId
                            ? t("auction.participants.download_review_file")
                            : t("auction.participants.no_review_file")
                          }
                        </Button>
                      </Grid>
                    : null
                  }
                </Grid>
              </Grid>
            : <Box>
                <Grid item xs={12} sx={{ mb: 1 }}>
                  <Typography variant="h6">
                    {auction
                      ? auctionStateArray("before", AuctionState.approvement).includes(auction?.state)
                        ? t("auction.participants.approvement_not_started")
                        : t("auction.participants.approvement_ended")
                      : "- - -"
                    }
                  </Typography>
                </Grid>
              </Box>
          : null
      }
    </Box>
  )
}
