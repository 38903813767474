import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import { AxiosError } from "axios";
import { useSelector } from "react-redux";
import { companyService } from "../../../../api/services/company";
import { publicService } from "../../../../api/services/public";
import { State } from "../../../../redux/store";
import { Auction } from "../../../../types/auction";
import { Company } from "../../../../types/company";
import digitsOnly from "../../../../utils/digitsOnly";
import { useSnack } from "../../../../utils/useSnack";
import { Response } from "../../../../types/api";
import { auctionService } from "../../../../api/services/auction";
import { useTranslation } from "react-i18next";

export default function Result({ 
  auctionId, auction, getAuction, participant
}: { 
  auctionId: string;
  auction: Auction | null;
  getAuction: () => void;
  participant: boolean;
}) {
  const { t } = useTranslation();
  const { snack } = useSnack();
  const state = useSelector((state: State) => state);
  const company: Company | null = state.company.company;
  const winningCompany = auction?.winningParticipant?.company;

  async function downloadResultFile() {
    if (!company || !auction?.resultReportFileId) { return }

    try {
      let fileTokenResponse: Response<{ id: string }>;

      if (participant) {
        fileTokenResponse = await auctionService.getFileToken(auctionId, company.id, auction.resultReportFileId);
      }
      else { 
        fileTokenResponse = await companyService.getFileToken(company.id, auction.resultReportFileId); 
      }

      if (fileTokenResponse.status === "success" && fileTokenResponse.data) {
        publicService.downloadFile(
          fileTokenResponse.data.id, 
          `${t("docs.result_protocol")}.pdf`
        );
      }
      else { snack(t("snack.error.file_download"), "error") }
    }
    catch (error) {
      const err = error as AxiosError;
      snack(t("snack.error.file_download"), "error"); 
    }
  }

  return (
    <Box>
      <Grid container gap={7}>
        <Grid item xs={12}>
          <Typography variant="h5">
            {t("auction.result.winner")}
          </Typography>
          <Divider sx={{ mt: 1, mb: 2 }} />
          <Grid container columnGap={7} rowGap={3}>
            <Grid>
              <Typography>{t("auction.result.company_title")}</Typography>
              <Typography variant="h6">{winningCompany?.title ?? ""}</Typography>
            </Grid>

            <Grid>
              <Typography>{t("auction.result.company_itn")}</Typography>
              <Typography variant="h6">{digitsOnly(winningCompany?.itn) ?? ""}</Typography>
            </Grid>
            
            <Grid>
              <Typography>{t("auction.result.ceo_name")}</Typography>
              <Typography variant="h6">{winningCompany?.ceoName ?? ""}</Typography>
            </Grid>
          </Grid>
        </Grid>
        
        <Grid>
          <Typography variant="h5">{t("auction.result.result_protocol")}</Typography>
          <Divider sx={{ mt: 1, mb: 2 }} />
          <Button onClick={downloadResultFile}>
            {t("auction.result.download_result_protocol")}
          </Button>
        </Grid>
      </Grid>
    </Box>
  )
}