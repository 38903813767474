import React from "react";
import { Autocomplete, Box, Button, Chip, Grid,
  LinearProgress,
  Modal, TextField, Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";
import { AxiosError } from "axios";
import { useTranslation } from "react-i18next";
import { Response } from "../../types/api";
import AnimateHeight from "react-animate-height";
import { companyService } from "../../api/services/company";
import { State } from "../../redux/store";
import { Company, CompanyFile } from "../../types/company";
import { FileCategory } from "../../utils/enums";
import { useDictionary } from "../../utils/useDictionary";
import { useSnack } from "../../utils/useSnack";
import { modalStyle } from "../modalStyle";

export const NewFileModal = ({
  isOpen, onClose, pickFile, getFiles
}: {
  isOpen: boolean,
  onClose: () => void;
  pickFile?: (file: CompanyFile) => void;
  getFiles?: () => void;
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const state = useSelector((state: State) => state);
	const company: Company | null = state.company.company;
  const { snack } = useSnack();

  const fileCategory = useDictionary(
    FileCategory, 
    (type) => ({value: type, label: t(`file_category.${type}`)}),
    { value: FileCategory.other, label: t("file_category.other") }
  );

  const [file, setFile] = React.useState<File | null>(null);
  const [title, setTitle] = React.useState("");
  const [description, setDescription] = React.useState("");

  const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.length) {
      const selected = e.target.files[0];
      const { type, size } = selected;
      const splittedFileName = selected.name.split('.');
      const extension = splittedFileName[splittedFileName.length - 1];

      if (type === "application/pdf" && extension === "pdf") {
        if (size < 10000000) { setFile(selected) }
        else {
          snack(t("snack.warning.file_size"), "warning")
        }
      }
      else {
        snack(t("snack.warning.not_pdf"), "warning")
      }
    }
  };
  
  const handleTextChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, 
    setState: React.Dispatch<React.SetStateAction<string>>
  ) => { setState(e.target.value) };

  const [isLoading, setIsLoading] = React.useState(false);
  const [fileResponse, setFileResponse] = 
    React.useState<Response<CompanyFile>>({
      code: "", data: null, status: "success"
    });

  const saveNewFile = async () => {
    if (!company) { return }

    if (file && fileCategory.selected?.value && title) {
      const formData = new FormData;
      formData.append("file", file);
      formData.append("category", fileCategory.selected?.value);
      formData.append("title", title);
      formData.append("description", description);

      try {
        setIsLoading(true);
        const result = await companyService.saveNewFile(company.id, formData);
        setFileResponse(result);
        if (pickFile && result.data) pickFile(result.data);
        if (getFiles && result.data) getFiles();
        snack(t("snack.success.file_uploaded"), "success"); 
        onCancel();
      }
      catch (error) {
        const err = error as AxiosError;
        snack(t("snack.error.default"), "error"); 
      }
      finally { setIsLoading(false) }
    }
    else {
      snack(t("snack.warning.choose_file_and_fill_name"), "warning");
    }
  };

  const onCancel = () => {
    setFile(null);
    setTitle("");
    setDescription("");
    onClose();
  }

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Box sx={modalStyle}>
        {/* header */}
          <Grid container spacing={2} justifyContent="space-between" sx={{ mb: 2 }}>
            <Grid item>
              <Typography component="h5" variant="h5" sx={{ fontSize: { xs: 14, sm: 20 } }}>
                {t("docs.new_document_upload")}
              </Typography>
            </Grid>
            <Grid item xs={2.2} sm={1.2}>
              <Button onClick={onClose} sx={{ 
                color: "black"
              }}>
                <CloseIcon />
              </Button>
            </Grid>
          </Grid>
        {/* / header */}

        <AnimateHeight height={isLoading ? 50 : "auto"} duration={200}>
          {isLoading 
            ? <Box>
                <LinearProgress sx={{ mt: 3, mb: 1 }} color="primary" />
                <Typography>{t("docs.loading")}</Typography>
              </Box>
            : <Grid container spacing={2} direction={"column"} 
                sx={{ minHeight: 50, maxHeight: { xs: 350, sm: 550 }, overflowY: "scroll" }}
              >
                <Grid item mb={1} textAlign="center">
                  {file
                    ? <Chip label={file.name} onDelete={() => setFile(null)} 
                            variant="outlined"
                            sx={{ mx: "auto", px: 2, py: 4, fontSize: 20 }}
                      />
                    : <Grid item>
                        <Button fullWidth size="small" component="label"
                                variant="outlined" color="primary" 
                                sx={{ px: 1, py: 2, fontSize: 18 }}
                        >
                          {t("docs.add_document")}
                          <input type="file" hidden onChange={onFileChange} 
                                accept="application/pdf" 
                          />
                        </Button>
                      </Grid>
                  }
                </Grid>

                <Grid item>
                  <Autocomplete
                    disablePortal size="small"
                    options={fileCategory.dictionary}
                    isOptionEqualToValue={(option, value) => option.value === value.value}
                    value={fileCategory.selected} 
                    onChange={fileCategory.onSelectChange}
                    renderInput={params => 
                      <TextField {...params} fullWidth color="primary" 
                                name="fileCategory" 
                                label={t("auction.dictionary.fileCategory")}
                      /> 
                    } 
                  />
                </Grid>

                <Grid item>
                  <TextField
                    fullWidth color="primary" type="text" size="small"
                    name="title" label={t("auction.fields.file_title")}
                    value={title}
                    onChange={e => handleTextChange(e, setTitle)}
                  />
                </Grid>

                <Grid item>
                  <TextField
                    fullWidth color="primary" type="text" size="small"
                    name="description" label={t("auction.fields.file_description")}
                    value={description}
                    onChange={e => handleTextChange(e, setDescription)}
                  />
                </Grid>
              </Grid>
          }
        </AnimateHeight>

        <Grid item display={"flex"} justifyContent={"flex-end"} mt={2}>
          <Button variant="contained" size="small" sx={{ mr: 1 }}
                  onClick={onCancel} disabled={isLoading} color="secondary"
          >
            {t("auction.modals.common.cancel")}
          </Button>
          <Button variant="contained" color="primary" size="small"
                  onClick={saveNewFile} disabled={isLoading}
          >
            {t("docs.upload_document")}
          </Button>
        </Grid>
      </Box>
    </Modal>
  )
}
