import { useState, ReactChild, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Container from '../../common/Container';
import { Topbar, Sidebar, Footer } from './components';
import { pages as accountPages } from '../navigation--account';
import { pages as moderatorPages } from '../navigation--moderator';
import { pages as adminPages } from '../navigation--admin';
import {Authority, CompanyState} from '../../utils/enums';
import { useCompany } from '../../utils/useCompany';
import { useLocation, useNavigate } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import {useSelector} from "react-redux";
import {State} from "../../redux/store";
import {Company} from "../../types/company";

const Cabinet = ({
  children,
  themeToggler,
  themeMode,
  setThemePalette,
  paletteType,
  role
}: {
  children: ReactChild;
  themeToggler: () => void;
  themeMode: "light" | "dark";
  setThemePalette: (type?: string) => void;
  paletteType: string;
  role: Authority;
}) => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
    
  const [openSidebar, setOpenSidebar] = useState(false);
  const handleSidebarOpen = () => setOpenSidebar(true);
  const handleSidebarClose = () => setOpenSidebar(false);

  function rolePages() {
    switch (role) {
      case Authority.USER: return accountPages
      case Authority.MODERATOR: return moderatorPages
      case Authority.ADMIN: return adminPages
      default: return accountPages
    }
  }

  const navigate = useNavigate();
  const { companiesLoading, companies, getCompanies, selectCompany, companyIdx, noCompanies} = useCompany();

  useEffect(() => { getCompanies(); }, []);
  useEffect(() => {
    if (companies.length) {
      const company: Company = selectCompany(companyIdx);
      if(role === Authority.USER && company?.state !== CompanyState.active) {
        navigate("/account-introduction")
      }
    }},[companies]);
  useEffect(() => { 
    if (role === Authority.USER && noCompanies) navigate("/page-no-companies") 
  }, [noCompanies]);

  return (
    <>
      {role === Authority.USER && companiesLoading
        ? <div style={{ 
            display: "flex", justifyContent: "center", alignItems: "center", 
            width: "100%", height: "100vh" 
          }}>
            <CircularProgress color="primary" />
          </div>
        : <Box height="100%" overflow="hidden" width="100%">
            <AppBar
              position={'fixed'}
              sx={{
                backgroundColor: theme.palette.background.paper,
              }}
              elevation={0}
            >
              <Container paddingY={{ xs: 1 / 2, sm: 1 }} maxWidth={{ md: '100%' }}>
                <Topbar
                  onSidebarOpen={handleSidebarOpen}
                  themeMode={themeMode}
                  themeToggler={themeToggler}
                  setThemePalette={setThemePalette}
                  paletteType={paletteType}
                />
              </Container>
              <Divider />
            </AppBar>
            <Sidebar
              onClose={handleSidebarClose}
              open={openSidebar}
              variant={isMd ? 'permanent' : 'temporary'}
              pages={rolePages()}
              themeMode={themeMode}
            />
            <main>
              <Box height={{ xs: 56, sm: 64 }} />
              <Box
                display="flex"
                flex="1 1 auto"
                overflow="hidden"
                paddingLeft={{ md: '256px' }}
              >
                <Box display="flex" flex="1 1 auto" overflow="hidden">
                  <Box flex="1 1 auto" display="flex" flexDirection="column" justifyContent="space-between" height={"calc(100vh - 64px)"} overflow="auto">
                    {children}
                    <Divider />
                    <Container paddingY={4}>
                      <Footer />
                    </Container>
                  </Box>
                </Box>
              </Box>
            </main>
          </Box>
      }
    </>
  );
};

Cabinet.propTypes = {
  children: PropTypes.node,
  themeToggler: PropTypes.func.isRequired,
  themeMode: PropTypes.string.isRequired,
  setThemePalette: PropTypes.func.isRequired,
  paletteType: PropTypes.string.isRequired,
};

export default Cabinet;
