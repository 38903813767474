import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Container from '../../../common/Container';
import { Button } from '@mui/material';
import { onLogout } from '../../../utils/auth';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const NoCompanies = ({ themeMode = 'light' }: { themeMode: "light" | "dark" }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Box>
      <Container>
        <Box textAlign={"center"}>
          <Typography
            sx={{
              textTransform: 'uppercase',
              fontWeight: 'medium',
            }}
            gutterBottom
            align={'center'}
            color={'textSecondary'}
          >
            {t("no_companies_page.no_companies")}
          </Typography>
          <Typography
            variant="h4"
            align={'center'}
            sx={{
              fontWeight: 700,
            }}
          >
            {t("no_companies_page.dear_user")}
          </Typography>
          <Box marginTop={4}>
            <Typography gutterBottom>
              {t("no_companies_page.welcome")}
            </Typography>
            <Typography gutterBottom>
              {t("no_companies_page.contact_manager")}
            </Typography>
          </Box>
          <Box marginTop={4}>
            <Typography
              variant="h6"
              gutterBottom
              sx={{
                fontWeight: 700,
              }}
            >
              {t("no_companies_page.how_contact_us")}
            </Typography>
            <Typography gutterBottom>
              team@bidhub.kz
            </Typography>
            <Typography gutterBottom>
              +7 7172 11 11 11
            </Typography>
            <Typography gutterBottom>
              {t("no_companies_page.city")}
            </Typography>
          </Box>

          <Button variant="contained" onClick={() => { onLogout(); navigate("/") }} sx={{ mt: 3 }}>
            {t("no_companies_page.logout")}
          </Button>
        </Box>
      </Container>
    </Box>
  );
};

NoCompanies.propTypes = {
  themeMode: PropTypes.string.isRequired,
};

export default NoCompanies;
