import {
  Box,
  FormControl,
  Tooltip,
  Select,
  MenuItem,
  FormHelperText,
  LinearProgress,
  SelectChangeEvent
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useCompany } from "../utils/useCompany";
import {setLSCompanyIdx} from "../utils/company";
import {CompanyState} from "../utils/enums";
import {useNavigate} from "react-router-dom";

export function CompaniesSelect({ themeMode, isUser, display }: {
  themeMode: "light" | "dark",
  isUser: boolean | null
  display: { md: string; sm: string; xs: string } 
}) {
  const { t } = useTranslation();
  const { companies, companyIdx, company, onCompanyChange } = useCompany();
  const navigate = useNavigate()
  const onCompanyChangeDecorator = (e: SelectChangeEvent<string>) => {
    const newCompany = onCompanyChange(e)
    if(isUser && newCompany.state !== CompanyState.active) {
      navigate("/account-introduction")
    }
  }
  return (
    <Box display={display} alignItems="center" minWidth={{ md: 250, sm: "unset" }} height={{ md: 40, sm: 87 }}>
      {companies.length
        ? <FormControl sx={{ flexDirection: { md: "row", sm: "column" }, alignItems: { md: "center", sm: "unset" }, width: "100%" }}>
            <FormHelperText>{t("cabinet.selected_company")}</FormHelperText>

            <Tooltip title={company ? company.title : ""} placement={"right"}>
              <Select
                value={`${companyIdx}`}
                onChange={onCompanyChangeDecorator}
                displayEmpty
                inputProps={{ 'aria-label': 'Without label' }}
                size="small"
              >
                {companies.map(({ id, title }, idx) => (
                  <MenuItem key={id} value={`${idx}`}>{title}</MenuItem>
                ))}
              </Select>
            </Tooltip>
          </FormControl>
        : <LinearProgress 
            color={themeMode === 'light' ? 'primary' : 'secondary'} 
            sx={{ width: "100%" }}
          />
      }
    </Box>
  )
}