import { AxiosResponse } from 'axios';
import { Announcement, NewAnnouncementData } from '../../types/announcement';
import { PaginationResponse, Response } from '../../types/api';
import { User } from '../../types/auth';
import { Company } from '../../types/company';
import { NewCompanyForm } from '../../types/newCompany';
import protectedInstance from '../instances/protected';
import publicInstance from '../instances/public';

async function getCompanies(
  text: string, page: number, pageSize: number
) {
  const query = `query=${text}&page=${page}&page_size=${pageSize}`;

  return protectedInstance
    .get(`/companies/all?${query}`)
    .then((res: AxiosResponse<PaginationResponse<Company[]>>) => res.data)
}

async function saveNewCompany(data: NewCompanyForm) {
  return protectedInstance
    .post("/company", data)
    .then((res: AxiosResponse<Response<any>>) => res.data)
}

async function getUsers(
  page?: number, pageSize?: number
) {
  const query = `page=${page ?? ""}&page_size=${pageSize ?? ""}`;

  return protectedInstance
    .get(`/users?${query}`)
    .then((res: AxiosResponse<PaginationResponse<User[]>>) => res.data)
}

async function saveNewAnnouncement(data: NewAnnouncementData) {
  return protectedInstance
    .post("/news", data)
    .then((res: AxiosResponse<Response<Announcement>>) => res.data)
}

async function getAnnouncements(
  page: number, pageSize: number
) {
  const query = `page=${page}&page_size=${pageSize}`;

  return publicInstance
    .get(`/v1/public/news?${query}`)
    .then((res: AxiosResponse<PaginationResponse<Announcement[]>>) => res.data)
}

async function editAnnouncement(id: string, data: NewAnnouncementData) {
  return protectedInstance
    .post(`/news/${id}`, data)
    .then((res: AxiosResponse<Response<Announcement>>) => res.data)
}

async function deleteAnnouncement(id: string) {
  return protectedInstance
      .delete(`/news/${id}`, {})
      .then((res: AxiosResponse<Response<Announcement>>) => res.data)
}

export const adminService = {
  getCompanies,
  saveNewCompany,
  getUsers,
  saveNewAnnouncement,
  getAnnouncements,
  editAnnouncement,
  deleteAnnouncement
};
