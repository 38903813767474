import { AxiosError } from "axios";
import jwtDecode from "jwt-decode";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { authService } from "../api/services/auth";
import { SET_USER } from "../redux/reducers/auth";
import { User } from "../types/auth";
import { DecodedToken } from "../types/token";
import { Authority } from "./enums";
import { getLSToken } from "./token";

export function useAuth() {
  const token = getLSToken();
  const decoded: DecodedToken | null = token ? jwtDecode(token.access_token) : null;
  const location = useLocation();
  const navigate = useNavigate();

  function roleNavigation() {
    if (!decoded) { return }

    switch (decoded.authorities[decoded.authorities.length - 1]) {
      case Authority.USER: 
        if (location.pathname.includes("/moderator-") || location.pathname.includes("/admin-")) {
          navigate("/account-introduction")
        }
        break;
      case Authority.MODERATOR: 
        if (location.pathname.includes("/account-") || location.pathname.includes("/admin-")) {
          navigate("/moderator-introduction")
        }
        break;
      case Authority.ADMIN: 
        if (location.pathname.includes("/account-") || location.pathname.includes("/moderator-")) {
          navigate("/admin-introduction")
        }
        break;
      
      default: navigate("/")
    }
  }

  const dispatch = useDispatch();
  const [user, setUser] = useState<User | null>(null);

  async function getUser() {
    if (!token) { return }

    try {
      const response = await authService.getUser();
      if (response.status === "success" && response.data) {
        dispatch({ type: SET_USER, payload: response.data });
        setUser(response.data);
      }
    }
    catch (error) {
      const err = error as AxiosError;
      console.error("ERROR", err.response);
    }
  }

  useEffect(() => { getUser(); }, []);
  useEffect(() => { roleNavigation(); }, [location.pathname]);
  
  return { user }
}