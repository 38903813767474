import React from "react";
import { Button, Divider, Grid, TextField, Typography } from "@mui/material";
import { useTranslation } from "react-i18next"
import { Auction } from "../../../../../../types/auction";
import { BidItemPrice } from "../../../../../../types/bids";
import { SigningBundle } from "../../Bids";
import SignatureBtn from "../../../../../../common/SignatureBtn";
import { Dictionary } from "../../../../../../types/dictionary";
import { ThousandsSeparator } from "../../../../../../common/NumberFormat";

export function Rubric({ 
	auction, 
	itemsPrices, 
	onItemPriceChange, 
	placeBid, 
	totalItemsPrices, 
	isOwned, 
	bidIdx,
	signingBundle
}: { 
	auction: Auction;
	itemsPrices?: BidItemPrice[];
	onItemPriceChange?(idx: number, newCode: string, newPrice: string): void; // tender bids
	placeBid?(): Promise<void>; // tender bids
	totalItemsPrices?: number;
	isOwned?: boolean;
	bidIdx?: number;
	signingBundle?: SigningBundle
}) {
	const { t } = useTranslation();

	return (
		<>
			{isOwned
				? null
				: <Grid container columnSpacing={3} rowSpacing={1} sx={{ mt: 1 }}>
						<Grid item xs={12} sx={{ mb: 1 }}>
							<Typography variant="h6">
								{t("auction.sections.rubric")}
							</Typography>
						</Grid>
						<Grid item xs={12} sm={6} md={4}>
							<TextField
								fullWidth color="primary" type="text" size="small"
								label={t("auction.dictionary.productType")}
								value={t(`product_type.${auction.productType}`)}
								InputProps={{ readOnly: true }}
							/>
						</Grid>
					</Grid>
			}

			<Divider sx={{ mt: isOwned ? 0 : 3, mb: isOwned ? 0 : 1, ml: "auto", mr: "auto", width: '85%' }} />

			{auction.items.map((item, idx, items) => (
				<React.Fragment key={idx}>
					<Grid container columnSpacing={3} rowSpacing={1} sx={{ mt: 1 }}>
						<Grid item xs={12} sx={{ mb: 1 }}>
							<b>
								{t("auction.fields.rubric.item")} 
								{items.length > 1 ? ` #${idx + 1}` : ""}
							</b>
						</Grid>
						<Grid item xs={12} sm={6} md={4}>
							<TextField
								fullWidth color="primary" type="text" size="small"
								label={t("auction.fields.rubric.productCode")}
								value={item.productCode}
								InputProps={{ readOnly: true }}
							/>
						</Grid>
						<Grid item xs={12} sm={6} md={8}>
							<TextField
								fullWidth color="primary" type="text" size="small"
								label={t("auction.fields.rubric.productName")}
								value={item.productName}
								InputProps={{ readOnly: true }}
							/>
						</Grid>
						{isOwned
							? null
							: <Grid item xs={12} sm={6} md={12} sx={{ mt: 1 }}>
									<TextField multiline minRows={4}
										fullWidth color="primary" type="text" size="small"
										label={t("auction.fields.rubric.productBrief")}
										value={item.productBrief}
										InputProps={{ readOnly: true }}
									/>
								</Grid>
						}
					</Grid>

					<Grid container columnSpacing={3} rowSpacing={1} sx={{ mt: isOwned ? 0 : 1 }}>
						<Grid item xs={12} sm={6} md={4}>
							<TextField
								fullWidth color="primary" type="text" size="small"
								label={t("auction.fields.rubric.price")}
								value={item.price}
								InputProps={{ readOnly: true, inputComponent: ThousandsSeparator }}
								InputLabelProps={{ shrink: true }}
							/>
						</Grid>
						<Grid item xs={12} sm={6} md={4}>
							<TextField
								fullWidth color="primary" type="text" size="small"
								label={t("auction.dictionary.unit")}
								value={t(`unit.${item.unit}`)}
								InputProps={{ readOnly: true }}
							/>
						</Grid>
						<Grid item xs={12} sm={6} md={4}>
							<TextField
								fullWidth color="primary" type="text" size="small"
								label={t("auction.fields.rubric.amount")}
								value={item.amount}
								InputProps={{ readOnly: true, inputComponent: ThousandsSeparator }}
								InputLabelProps={{ shrink: true }}
							/>
						</Grid>
					</Grid>

					{placeBid || isOwned
						? null
						: <>
								<Grid container columnSpacing={3} rowSpacing={1} sx={{ mt: 1 }}>
									<Grid item xs={12} sm={6} md={4}>
										<TextField
											fullWidth color="primary" type="text" size="small"
											label={t("auction.dictionary.deliveryRegion")}
											value={t(`delivery_region.${item.deliveryRegion}`)}
											InputProps={{ readOnly: true }}
										/>
									</Grid>
									<Grid item xs={12} sm={6} md={8}>
										<TextField
											fullWidth color="primary" type="text" size="small"
											label={t("auction.fields.rubric.deliveryLocation")}
											value={item.deliveryLocation}
											InputProps={{ readOnly: true }}
										/>
									</Grid>
								</Grid>
							</>
					}

					{placeBid || isOwned
						? <>
								{isOwned
									? null
									: <Divider sx={{ mt: 3, mb: 1, ml: "auto", mr: "auto", width: '98%' }} />
								}
							
								<Grid container columnSpacing={3} rowSpacing={1} sx={{ mt: 1 }}>
									<Grid item xs={12}>
										<TextField
											fullWidth color="primary" type="number" size="small"
											label={isOwned
												? t("auction.fields.bid.offeredPrice")
												: t("auction.fields.bid.yourPrice")
											}
											onChange={(e) => {
												if (!onItemPriceChange) return;
												onItemPriceChange(idx, item.productCode, e.target.value)
											}}
											value={auction.isBidPlaced || isOwned
												? isOwned && bidIdx !== undefined
													? auction.bids[bidIdx]?.items[idx].price
													: auction.bid.items[idx].price
												: itemsPrices ? itemsPrices[idx].price : ""
											}
											InputProps={{ 
												readOnly: auction.isBidPlaced || isOwned ? true : false,
												inputComponent: ThousandsSeparator
											}}
											onBlur={() => {
												if (auction.isBidPlaced || isOwned) {
													if (isOwned && bidIdx !== undefined) {
														if (+auction.bids[bidIdx]?.items[idx].price === 0) {
															if (!onItemPriceChange) return;
															onItemPriceChange(idx, item.productCode, "")
														}
														else if (+auction.bid.items[idx].price === 0) { 
															if (!onItemPriceChange) return;
															onItemPriceChange(idx, item.productCode, "") 
														}
													}
													else if (itemsPrices) {
														if (+itemsPrices[idx].price === 0) {
															if (!onItemPriceChange) return;
															onItemPriceChange(idx, item.productCode, "") 
														}
													}
												}
											}}
										/>
									</Grid>
								</Grid>
							</>
						: null
					}
				</React.Fragment>
			))}

			<Divider sx={{ mt: isOwned ? 0 : 3, mb: isOwned ? 0 : 1, ml: "auto", mr: "auto", width: '98%' }} />

			<Grid container columnSpacing={3} rowSpacing={1} sx={{ mt: 1 }}>
				<Grid item xs={12} sm={6} md={4}>
					<TextField
						fullWidth color="primary" type="text" size="small"
						label={placeBid 
							? t("auction.fields.bid.startingGross") 
							: t("auction.fields.rubric.gross") 
						}
						value={auction.gross}
						InputProps={{ readOnly: true, inputComponent: ThousandsSeparator }}
						InputLabelProps={{ shrink: true }}
					/>
				</Grid>

				{placeBid || isOwned
					? <Grid item xs={12} sm={6} md={4}>
							<TextField
								fullWidth color="primary" type="text" size="small"
								label={isOwned
									? t("auction.fields.bid.offeredGross")
									: t("auction.fields.bid.yourGross")}
								value={isOwned && bidIdx !== undefined
									? auction.bids[bidIdx].gross
									: totalItemsPrices ?? ""}
								InputProps={{ readOnly: true, inputComponent: ThousandsSeparator }}
								InputLabelProps={{ shrink: true }}
							/>
						</Grid>
					: null
				}
			</Grid>

			<Divider sx={{ mt: isOwned ? 0 : 3, ml: "auto", width: '98%' }} />

			{placeBid || isOwned
				? isOwned
					? null
					: <Grid item container spacing={2} justifyContent="center" sx={{ pt: 2 }}>
							<SignatureBtn 
								onSign={signingBundle?.onSign as () => Promise<void>}
								isSigning={signingBundle?.isSigning as boolean}
								signingError={signingBundle?.signingError as string}
								storageType={signingBundle?.storageType as Dictionary}
								signed={(signingBundle?.signature || auction.isBidPlaced) ? true : false}
							/>

							<Grid item xs="auto">
								<Button fullWidth variant="contained" size="large"
												onClick={placeBid} color="primary" 
												disabled={!signingBundle?.signature || auction.isBidPlaced}
								>
									{auction.isBidPlaced
										? t("auction.fields.bid.placed")
										: t("auction.fields.bid.place")
									}
								</Button>
							</Grid>
						</Grid>

				: <Grid container columnSpacing={3} rowSpacing={1} sx={{ mt: 1 }}>
						<Grid item xs={12} sx={{ mb: 1 }}>
							<Typography variant="h6">
								{t("auction.sections.terms")}
							</Typography>
						</Grid>
						<Grid item xs={12} sm={6} md={4}>
							<TextField
								fullWidth color="primary" type="text" size="small"
								label={t("auction.dictionary.deliveryCondition")}
								value={t(`delivery_condition.${auction.deliveryCondition}`)}
								InputProps={{ readOnly: true }}
							/>
						</Grid>
						<Grid item xs={12} sm={6} md={4}>
							<TextField
								fullWidth color="primary" type="text" size="small"
								label={t("auction.fields.deliveryTime")}
								value={auction.deliveryTime}
								InputProps={{ readOnly: true }}
							/>
						</Grid>
					</Grid>
				}
		</>
	)
}