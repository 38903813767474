import {t} from "i18next";
import { NewAnnouncementForm } from "../../../types/announcement";
import * as Yup from "yup";

const initialValues: NewAnnouncementForm = {
  title: "",
  content: "",
  publishedTime: null
}

function ValidationSchema(
  currentDate: Date
) {
  return Yup.object({
    title: Yup.string().required(t("validation.required")),
    content: Yup.string().required(t("validation.required")),
    publishedTime: Yup.date()
      .typeError(t("validation.requiredDateAndTime"))
      .min(currentDate, t("validation.published_time"))
  })
}

export {
  initialValues,
  ValidationSchema
}