import { Grid, TextField, FormControlLabel, Checkbox } from "@mui/material";
import { FormikProps, Field, FieldProps, Formik } from "formik";
import { RefObject } from "react";
import { useTranslation } from "react-i18next";
import { NewCompanyOtherForm } from "../../../../../types/newCompany";
import { initialValues, ValidationSchema } from "./formik";

export function OtherFields({
  formikRef
}: {
  formikRef: RefObject<FormikProps<NewCompanyOtherForm>>;
}) {
  const onSubmit = (values: NewCompanyOtherForm) => {};
  const { t } = useTranslation();

  return (
    <Formik innerRef={formikRef}
      initialValues={initialValues}
      validationSchema={ValidationSchema()}
      onSubmit={onSubmit}
      validateOnMount
    >
      {(frops: FormikProps<NewCompanyOtherForm>) => (
        <Grid container columnSpacing={3} rowSpacing={1}>
          <Grid item xs={12} sm={6} md={4}>
            <Field name={"reviewComment"}>
              {({ field, form, meta }: FieldProps) => (
                <TextField {...field}
                  fullWidth color="primary" type="text" size="small"
                  label={t("auction.fields.company.review_comment")}
                  error={(meta.touched && meta.error) ? true : false}
                  helperText={meta.touched && meta.error}
                />
              )}
            </Field>
          </Grid>
        </Grid>
      )}
    </Formik>
  )
}