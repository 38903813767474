import { combineReducers, createStore } from "redux";
import { composeWithDevTools } from "@redux-devtools/extension";
import { publicReducer } from "./reducers/public";
import { companyReducer } from "./reducers/company";
import { auctionReducer } from "./reducers/auction";
import { authReducer } from "./reducers/auth";
import { announcementReducer } from "./reducers/announcement";

const rootReducer = combineReducers({
  public: publicReducer,
  company: companyReducer,
  auction: auctionReducer,
  auth: authReducer,
  announcement: announcementReducer,
});

export const store = createStore(
  rootReducer,
  composeWithDevTools()
);

export type State = ReturnType<typeof rootReducer>;