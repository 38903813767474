import { useState } from "react";
import { Button, Divider, Grid, LinearProgress, Paper, TextField, 
  Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { Box } from "@mui/system";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { State } from "../../../redux/store";
import { useSnack } from "../../../utils/useSnack";
import { Company } from "../../../types/company";
import digitsOnly from "../../../utils/digitsOnly";

export default function CompanyDetails() {
  const { t } = useTranslation();
  const state = useSelector((state: State) => state);
  const company: Company | null = state.company.company;

  return (
    <Paper sx={{ pl: 4, pr: 4, pt: 2, pb: 4 }} elevation={10}>
      <Grid container spacing={3} justifyContent="space-between">
        <Grid item>
          <Typography variant="h5">
            {t("cabinet.company_details.profile")} {company ? `- ${company.shortTitle}` : ""}
          </Typography>
        </Grid>
      </Grid>

      <Divider sx={{ mt: 2, mb: 6, width: "100%" }} />

      {!company 
        ? <Box>
            <LinearProgress sx={{ mt: 3, mb: 1 }} color="primary" />
            <Typography>{t("cabinet.company_details.loading")}</Typography>
          </Box>
        : <Grid container rowSpacing={3}>
            <Grid container columnSpacing={3} rowSpacing={1}>
              <Grid item xs={12} sx={{ mb: 1 }}>
                <Typography variant="h6">
                  {t("cabinet.company_details.owner")}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  fullWidth color="primary" type="text" size="small"
                  label={t("auction.fields.company.owner_email")}
                  value={company.ownerData.username}
                  InputProps={{ readOnly: true }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  fullWidth color="primary" type="text" size="small"
                  label={t("auction.fields.company.owner_name")}
                  value={company.ownerData.name}
                  InputProps={{ readOnly: true }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  fullWidth color="primary" type="text" size="small"
                  label={t("auction.fields.company.owner_phone")}
                  value={company.ownerData.mobile}
                  InputProps={{ readOnly: true }}
                />
              </Grid>
            </Grid>

            <Divider sx={{ my: 2, ml: "auto", width: '98%' }} />

            <Grid container columnSpacing={3} rowSpacing={1}>
              <Grid item xs={12} sx={{ mb: 1 }}>
                <Typography variant="h6">
                  {t("cabinet.company_details.ceo")}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  fullWidth color="primary" type="text" size="small"
                  label={t("auction.fields.company.ceo_phone")}
                  value={company.ceoPhone}
                  InputProps={{ readOnly: true }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  fullWidth color="primary" type="text" size="small"
                  label={t("auction.fields.company.ceo_name")}
                  value={company.ceoName}
                  InputProps={{ readOnly: true }}
                />
              </Grid>
            </Grid>

            <Divider sx={{ my: 2, ml: "auto", width: '98%' }} />

            <Grid container columnSpacing={3} rowSpacing={1}>
              <Grid item xs={12} sx={{ mb: 1 }}>
                <Typography variant="h6">
                  {t("cabinet.company_details.organization")}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  fullWidth color="primary" type="text" size="small"
                  label={t("auction.fields.company.itn")}
                  value={digitsOnly(company.itn)}
                  InputProps={{ readOnly: true }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  fullWidth color="primary" type="text" size="small"
                  label={t("auction.fields.company.title")}
                  value={company.title}
                  InputProps={{ readOnly: true }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  fullWidth color="primary" type="text" size="small"
                  label={t("auction.fields.company.short_title")}
                  value={company.shortTitle}
                  InputProps={{ readOnly: true }}
                />
              </Grid>
            </Grid>

            <Grid container columnSpacing={3} rowSpacing={1} sx={{ mt: 1 }}>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  fullWidth color="primary" type="text" size="small"
                  label={t("auction.fields.company.legal_form")}
                  value={t(`legal_form.${company.legalForm}`)}
                  InputProps={{ readOnly: true }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  fullWidth color="primary" type="text" size="small"
                  label={t("auction.fields.company.size")}
                  value={t(`size.${company.size}`)}
                  InputProps={{ readOnly: true }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  fullWidth color="primary" type="text" size="small"
                  label={t("auction.fields.company.ownership_form")}
                  value={t(`ownership.${company.ownershipForm}`)}
                  InputProps={{ readOnly: true }}
                />
              </Grid>
            </Grid>

            <Divider sx={{ my: 2, ml: "auto", width: '98%' }} />

            <Grid container columnSpacing={3} rowSpacing={1}>
              <Grid item xs={12} sx={{ mb: 1 }}>
                <Typography variant="h6">Контакты</Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  fullWidth color="primary" type="text" size="small"
                  label={t("auction.fields.company.legal_address")}
                  value={company.legalAddress}
                  InputProps={{ readOnly: true }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  fullWidth color="primary" type="text" size="small"
                  label={t("auction.fields.company.actual_address")}
                  value={company.actualAddress}
                  InputProps={{ readOnly: true }}
                />
              </Grid>
            </Grid>

            <Grid container columnSpacing={3} rowSpacing={1} sx={{ mt: 1 }}>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  fullWidth color="primary" type="text" size="small"
                  label={t("auction.fields.company.phone")}
                  value={company.phone}
                  InputProps={{ readOnly: true }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  fullWidth color="primary" type="text" size="small"
                  label={t("auction.fields.company.email")}
                  value={company.email}
                  InputProps={{ readOnly: true }}
                />
              </Grid>
            </Grid>
          </Grid>
      }
		</Paper>
  )
}
