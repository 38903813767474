import React, { useState } from "react";
import { Box, Button, Grid, LinearProgress, Modal, Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { AxiosError } from "axios";
import AnimateHeight from "react-animate-height";
import { useSelector } from "react-redux";
import { auctionService } from "../../../../../../api/services/auction";
import { useSnack } from "../../../../../../utils/useSnack";
import { Company } from "../../../../../../types/company";
import { State } from "../../../../../../redux/store";
import { useTranslation } from "react-i18next";
import { modalStyle } from "../../../../../../common/modalStyle";

export const CancelModal = ({
  isOpen, onClose, auctionId, onAuctionClose
}: {
  isOpen: boolean;
  onClose: () => void;
  auctionId: string;
  onAuctionClose: () => void;
}) => {
  const { t } = useTranslation();
  const { snack } = useSnack();
  const state = useSelector((state: State) => state);
  const company: Company | null = state.company.company;

  const [isLoading, setIsLoading] = useState(false);
  async function cancelAuction() {
    if (!company) return;

    try {
      const response = await auctionService.cancelAuction(company.id, auctionId);
      if (response.status === "success" && response.data) {
        snack(t("snack.success.auction_canceled"), "success");
      }
      else { snack(t("snack.error.auction_cancel"), "error") }
      onAuctionClose();
    }
    catch (error) {
      const err = error as AxiosError;
      snack(t("snack.error.auction_cancel"), "error"); 
      onCancel()
    }
    finally { setIsLoading(false) }
  }

  const onCancel = () => { onClose() };
  
  return (
    <Modal open={isOpen} onClose={onClose}>
      <Box sx={modalStyle}>
        {/* header */}
          <Grid container spacing={2} justifyContent="space-between" sx={{ mb: 2 }}>
            <Grid item>
              <Typography component="h5" variant="h5" sx={{ fontSize: { xs: 14, sm: 20 } }}>
                {t("auction.modals.cancel.title")}
              </Typography>
            </Grid>
            <Grid item xs={2.2} sm={1.2}>
              <Button onClick={onClose} sx={{ 
                color: "black"
              }}>
                <CloseIcon />
              </Button>
            </Grid>
          </Grid>
        {/* / header */}

        <AnimateHeight height={isLoading ? 50 : "auto"} duration={200}>
          {isLoading 
            ? <Box>
                <LinearProgress sx={{ mt: 3, mb: 1 }} color="primary" />
                <Typography>{t("auction.modals.cancel.cancelling")}</Typography>
              </Box>
            : <Grid container spacing={2} direction={"column"}>
                <Grid item>
                  <Typography>{t("auction.modals.cancel.are_you_sure")}</Typography>
                </Grid>
              </Grid>
          }
        </AnimateHeight>

        <Grid item display={"flex"} justifyContent={"flex-end"} mt={2}>
          <Button variant="contained" size="small" sx={{ mr: 1 }}
                  onClick={onCancel} disabled={isLoading} color="secondary"
          >
            {t("auction.modals.common.cancel")}
          </Button>
          <Button variant="contained" color="primary" size="small"
                  onClick={cancelAuction} disabled={isLoading}
          >
            {t("auction.modals.cancel.confirm")}
          </Button>
        </Grid>
      </Box>
    </Modal>
  )
}
