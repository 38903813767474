import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { alpha, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import { Pages } from '../../../../../../types/pages';
import { onLogout } from '../../../../../../utils/auth';
import { useSelector } from 'react-redux';
import { CompaniesSelect } from '../../../../../../common/CompaniesSelect';
import { User } from '../../../../../../types/auth';
import { Authority } from '../../../../../../utils/enums';
import { State } from '../../../../../../redux/store';
import { useLocation } from 'react-router-dom';
import { useTranslatePages } from '../../../../../../utils/useTranslatePages';

const SidebarNav = ({ pages, onClose, themeMode }: {
  pages: Pages;
  onClose: () => void;
  themeMode: "light" | "dark"
}) => {
  const { translatePages } = useTranslatePages();
  const theme = useTheme();
  const state = useSelector((state: State) => state);
  const user: User | null = state.auth.user;
  const isUser = user && user.authorities[user.authorities.length - 1] === Authority.USER;
  const location = useLocation();
  const pathArray = location.pathname.split("/");

  const [activeLink, setActiveLink] = useState('');
  useEffect(() => {
    setActiveLink(window && window.location ? `/${pathArray[1]}` : '');
  }, [window.location.pathname]);

  return (
    <Box>
      <Box
        justifyContent={'flex-end'}
        onClick={() => onClose()}
        sx={{ display: { md: 'none', sm: 'flex' } }}
      >
        <CloseIcon fontSize="small" />
      </Box>
      <Box>
        {translatePages(pages).map((item, i) => (
          <Box key={i} marginBottom={3}>
            <Typography
              variant="caption"
              sx={{
                fontWeight: 700,
                textTransform: 'uppercase',
                marginBottom: 1,
                display: 'block',
              }}
            >
              {item.title}
            </Typography>
            <Box>
              {item.pages.map((p, i) => (
                <Box marginBottom={1 / 2} key={i}>
                  <Button
                    component={'a'}
                    href={p.href}
                    target={p.target}
                    fullWidth
                    sx={{
                      justifyContent: 'flex-start',
                      color:
                        activeLink === p.href
                          ? theme.palette.primary.main
                          : theme.palette.text.primary,
                      backgroundColor:
                        activeLink === p.href
                          ? alpha(theme.palette.primary.main, 0.1)
                          : 'transparent',
                      fontWeight: activeLink === p.href ? 600 : 400,
                    }}
                    onClick={() => onClose()}
                  >
                    {p.title}
                  </Button>
                </Box>
              ))}
            </Box>
          </Box>
        ))}
      </Box>

      {isUser
        ? <CompaniesSelect themeMode={themeMode} isUser={isUser} display={{ md: "none", sm: "flex", xs: "flex" }} />
        : null
      }
    </Box>
  );
};

SidebarNav.propTypes = {
  pages: PropTypes.array.isRequired,
  onClose: PropTypes.func,
};

export default SidebarNav;
