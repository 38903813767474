import { useState, useEffect } from "react";
import { Token } from "../types/token";
import { getLSToken } from "./token";

export function useToken() {
  const [token, setToken] = useState<Token & { expires: number } | undefined>(undefined);
  
  useEffect(() => {
    const token = getLSToken();
    if (token) { 
      setToken(token);
    }
    else { console.warn("WARNING", "No token"); }
  }, []);

  return { token }
}