import axios, { AxiosRequestConfig, AxiosResponse } from "axios";

const publicInstance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/api`,
  headers: { "Content-Type": "application/json" }
});

async function onRequestFulfilled(config: AxiosRequestConfig<any>) {
  return config;
}
function onRequestRejected(error: any) { Promise.reject(error) }

async function onResponseFulfilled(response: AxiosResponse<any, any>) {
  return response
}
function onResponseRejected(error: any) { return Promise.reject(error) }

publicInstance.interceptors.request.use(onRequestFulfilled, onRequestRejected);
publicInstance.interceptors.response.use(onResponseFulfilled, onResponseRejected);

export default publicInstance;
