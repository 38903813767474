import React, { useEffect, useRef } from "react";
import { Button, Chip, Grid, LinearProgress, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { AxiosError } from "axios";
import { useTranslation } from "react-i18next";
import { Field, FieldProps, Formik, FormikProps } from "formik";
import { initialValues, ValidationSchema } from "./formik";
import { companyService } from "../../../../../api/services/company";
import { publicService } from "../../../../../api/services/public";
import { ArchivedFilesModal } from "../../../../../common/FilesModals/Archived";
import { NewFileModal } from "../../../../../common/FilesModals/New";
import { State } from "../../../../../redux/store";
import { Company, CompanyFile } from "../../../../../types/company";
import { NewAuctionFilesForm } from "../../../../../types/newAuction";
import { useSnack } from "../../../../../utils/useSnack";

export const FilesFields = ({
  formikRef, editingInitialState
}: {
  formikRef: React.RefObject<FormikProps<NewAuctionFilesForm>>;
  editingInitialState?: NewAuctionFilesForm
}) => {
  const onSubmit = (values: NewAuctionFilesForm) => {};

  const { t } = useTranslation();
  const { snack } = useSnack();
  const state = useSelector((state: State) => state);
	const company: Company | null = state.company.company;

  const pickFile = (file: CompanyFile) => {
    if (formikRef.current?.values.attachments.filter(
      item => item.id === file.id).length === 0
    ) {
      formikRef.current?.setFieldValue("attachments", 
        [...formikRef.current.values.attachments, file]
      );
      snack(t("snack.success.document_attached"), "success")
    }
    else { snack(t("snack.info.document_already_attached"), "info")}
  };
  const removeFile = (fileId: string) => {
    formikRef.current?.setFieldValue("attachments", 
      formikRef.current.values.attachments.filter(file => file.id !== fileId)
    );
  };

  const [downloadingId, setDownloadingId] = React.useState("");
  const downloadFile = async (file: CompanyFile) => {
    if (!company) { return }

    try {
      setDownloadingId(file.id);
      const fileTokenResponse = await companyService.getFileToken(company.id, file.id);
      if (fileTokenResponse.status === "success" && fileTokenResponse.data) {
        publicService.downloadFile(fileTokenResponse.data.id, file.name);
      }
      else { snack(t("snack.error.file_download"), "error") }
    }
    catch (error) {
      const err = error as AxiosError;
      snack(t("snack.error.file_download"), "error"); 
    }
    finally { setDownloadingId("") }
  }

  const [isArchivedOpen, setIsArchivedOpen] = React.useState(false);
  const onOpenArchived = () => setIsArchivedOpen(true);
  const onCloseArchived = () => setIsArchivedOpen(false);
  
  const [isNewOpen, setIsNewOpen] = React.useState(false);
  const onOpenNew = () => setIsNewOpen(true);
  const onCloseNew = () => setIsNewOpen(false);
  
  const renderCount = useRef(0);
  useEffect(() => {
    if (renderCount.current >= 1) {
      formikRef.current?.setFieldValue("attachments", []);
    }
    renderCount.current += 1;
  }, [company]);

  useEffect(() => {console.log(editingInitialState)}, [])

  return (
    <Formik innerRef={formikRef}
      initialValues={editingInitialState || initialValues} 
      validationSchema={ValidationSchema()}
      onSubmit={onSubmit}
      validateOnMount
    >
      {(frops: FormikProps<NewAuctionFilesForm>) => (
        <Grid container columnSpacing={3} rowSpacing={1}>
          <ArchivedFilesModal 
            isOpen={isArchivedOpen} onClose={onCloseArchived} 
            pickFile={pickFile} downloadFile={downloadFile}
            downloadingId={downloadingId} onOpenNew={onOpenNew}
          />
          <NewFileModal
            isOpen={isNewOpen} onClose={onCloseNew} 
            pickFile={pickFile}
          />

          <Grid item xs={12} sx={{ mb: 1 }}>
            <Typography variant="h6">
              {t("auction.sections.docs")}
            </Typography>
          </Grid>

          <Field name={"attachments"}>
            {({ field, form, meta }: FieldProps) => (
              <Grid item>
                <Grid item container columnSpacing={3} rowSpacing={1}>
                  <Grid item>
                    <Button color={meta.touched && meta.error ? "error" : "primary"} 
                            variant="contained" size="small" onClick={onOpenArchived}
                    >
                      {t("auction.files.add_archived")}
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button color={meta.touched && meta.error ? "error" : "primary"}
                            variant="contained" size="small" onClick={onOpenNew}
                    >
                      {t("auction.files.add_new")}
                    </Button>
                  </Grid>
                </Grid>

                {(meta.touched && meta.error) &&
                  <Typography 
                    color="#d32f2f" fontSize="0.6428571428571428rem" 
                    mt="4px" mx="14px" mb="0"
                  >
                    {meta.error}
                  </Typography>
                }
              </Grid>
            )}
          </Field>

          <Grid item container columnSpacing={2} rowSpacing={1}>
            {frops.values.attachments.map(file => {
              return (
                <Grid item key={file.id}>
                  <Typography fontSize={12} textAlign={"center"} mt={1} mb={.5}>
                    {t(`file_category.${file.category}`)}
                  </Typography>

                  <Chip key={file.id}
                    label={file.title || file.name}
                    onClick={() => downloadFile(file)}
                    disabled={downloadingId === file.id}
                    onDelete={() => removeFile(file.id)}
                  />

                  {downloadingId === file.id &&
                    <LinearProgress color="primary" />          
                  }
                </Grid>
              )
            })}
          </Grid>
        </Grid>
      )}
    </Formik>
  )
}
