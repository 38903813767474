import { AxiosError } from "axios";
import { authService } from "../api/services/auth";
import { Token } from "../types/token";

export function calculateTokenExpires(expiresIn: number) {
  const TOKEN_REFRESH_THRESHOLD = 120;

  const tokenExpires = new Date();
  tokenExpires.setSeconds(
    tokenExpires.getSeconds() + expiresIn - TOKEN_REFRESH_THRESHOLD
  );
  return tokenExpires.getTime()
}

export function setLSToken(token: Token) {
  localStorage.setItem("token", JSON.stringify({ ...token,
    expires: calculateTokenExpires(token.expires_in)
  }))
}

export function removeLSToken() {
  localStorage.removeItem("token");
}

export function getLSToken() {
  const token = localStorage.getItem("token");
  if (!token) { 
    removeLSToken();
    const path = window.location.pathname;
    if (path !== "/" && !path.includes("/page-")) document.location.href = '/';
    return
  }
  const parsedToken: Token & { expires: number } = JSON.parse(token);
  return parsedToken;
}

export async function refreshToken() {
  const parsedToken = getLSToken();
  if (!parsedToken) { return }
  const data = { refreshToken: parsedToken.refresh_token };

  try {
    const response = await authService.refreshToken(data);
    const token = response.data;
    if (!token) { return }
    setLSToken(token)
  }
  catch (error) {
    const err = error as AxiosError;
    console.error("ERROR", err.response);

    // не удалось обновить токен, сессия закрылась, выход на главную
    const path = window.location.pathname;
    removeLSToken();
    if (path !== '/') document.location.href = '/';
  }
}

export async function getAccessToken() {
  const parsedToken = getLSToken();
  if (!parsedToken) { return }

  // проверяем устарел ли токен
  if (new Date().getTime() >= parsedToken.expires) {
    // токен устарел, обновляем
    console.warn("TOKEN REFRESH");
    await refreshToken();
    return getLSToken();
  }

  return parsedToken.access_token;
}