import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '../../../common/Container';
import { useTranslation } from 'react-i18next';

const Introduction = ({ themeMode = 'light' }: { themeMode: "light" | "dark" }) => {
  const { t } = useTranslation();

  return (
    <Box>
      <Container>
        <Box>
          <Typography
            sx={{
              textTransform: 'uppercase',
              fontWeight: 'medium',
            }}
            gutterBottom
            align={'center'}
            color={'textSecondary'}
          >
            {t("cabinet.admin_intro.suptitle")}
          </Typography>
          <Typography
            variant="h4"
            align={'center'}
            sx={{
              fontWeight: 700,
            }}
          >
            {t("cabinet.admin_intro.title")}
          </Typography>
          <Box marginTop={4}>
            <Typography gutterBottom>
              •	{t("cabinet.admin_intro.text1")}
            </Typography>
            <Typography gutterBottom>
              •	{t("cabinet.admin_intro.text2")}
            </Typography>
            <Typography gutterBottom>
              •	{t("cabinet.admin_intro.text3")}
            </Typography>
          </Box>
          <Box marginTop={4}>
            <Typography
              variant="h6"
              gutterBottom
              sx={{
                fontWeight: 700,
              }}
            >
              {t("cabinet.admin_intro.feedback")}
            </Typography>
            <Typography gutterBottom>
              {t("cabinet.admin_intro.schedule")}
            </Typography>
            <Typography gutterBottom>
              {t("cabinet.admin_intro.support_email")}
            </Typography>
            <Typography gutterBottom>
              {t("cabinet.admin_intro.call_center")}
            </Typography>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

Introduction.propTypes = {
  themeMode: PropTypes.string.isRequired,
};

export default Introduction;
