import React from "react";
import { Autocomplete, Box, Button, Chip, Grid,
  LinearProgress,
  Modal, TextField, Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";
import { AxiosError } from "axios";
import { useTranslation } from "react-i18next";
import AnimateHeight from "react-animate-height";
import { auctionService } from "../../../../../../api/services/auction";
import { useSnack } from "../../../../../../utils/useSnack";
import { State } from "../../../../../../redux/store";
import { Company } from "../../../../../../types/company";
import { modalStyle } from "../../../../../../common/modalStyle";

export function ReviewModal({
  isOpen, onClose, auctionId, getAuction
}: {
  isOpen: boolean,
  onClose: () => void;
  auctionId: string;
  getAuction: () => Promise<void>;
}) {
  const { t } = useTranslation();
  const { snack } = useSnack();
  const state = useSelector((state: State) => state);
	const company: Company | null = state.company.company;

  const [file, setFile] = React.useState<File | null>(null);

  const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.length) {
      const selected = e.target.files[0];
      const { type, size } = selected;
      const splittedFileName = selected.name.split('.');
      const extension = splittedFileName[splittedFileName.length - 1];

      if (type === "application/pdf" && extension === "pdf") {
        if (size < 10000000) { setFile(selected) }
        else { snack(t("snack.warning.file_size"), "warning") }
      }
      else { snack(t("snack.warning.not_pdf"), "warning") }
    }
  };

  const [isLoading, setIsLoading] = React.useState(false);

  const saveReviewFile = async () => {
    if (!company) { return }

    if (file) {
      const formData = new FormData;
      formData.append("file", file);

      try {
        setIsLoading(true);
        const response = await auctionService.saveParticipationReview(company.id, auctionId, formData);
        if (response.status === "success") {
          snack(t("snack.success.participation_review"), "success");
          getAuction();
          onCancel();
        }
        else { snack(t("snack.error.participation_review"), "error");  }
      }
      catch (error) {
        const err = error as AxiosError;
        snack(t("snack.error.participation_review"), "error"); 
      }
      finally { setIsLoading(false) }
    }
    else { snack(t("snack.warning.choose_document"), "warning") }
  };

  const onCancel = () => {
    setFile(null);
    onClose();
  }

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Box sx={modalStyle}>
        {/* header */}
          <Grid container spacing={2} justifyContent="space-between" sx={{ mb: 2 }}>
            <Grid item>
              <Typography component="h5" variant="h5" sx={{ fontSize: { xs: 14, sm: 20 } }}>
                {t("auction.modals.review.title")}
              </Typography>
            </Grid>
            <Grid item xs={2.2} sm={1.2}>
              <Button onClick={onClose} sx={{ 
                color: "black"
              }}>
                <CloseIcon />
              </Button>
            </Grid>
          </Grid>
        {/* / header */}

        <AnimateHeight height={isLoading ? 50 : "auto"} duration={200}>
          {isLoading 
            ? <Box>
                <LinearProgress sx={{ mt: 3, mb: 1 }} color="primary" />
                <Typography>{t("auction.modals.review.loading")}</Typography>
              </Box>
            : <Grid container spacing={2} direction={"column"} 
                sx={{ minHeight: 50, maxHeight: { xs: 350, sm: 550 } }}
              >
                <Grid item textAlign="center">
                  {file
                    ? <Chip label={file.name} onDelete={() => setFile(null)} 
                            variant="outlined"
                            sx={{ mx: "auto", px: 2, py: 4, fontSize: 20 }}
                      />
                    : <Grid item sx={{ width: "100%" }}>
                        <Button fullWidth size="small" component="label"
                                variant="outlined" color="primary" 
                                sx={{ px: 1, py: 2, fontSize: 18 }}
                        >
                          {t("auction.modals.review.add_review_file")}
                          <input type="file" hidden onChange={onFileChange} 
                                accept="application/pdf" 
                          />
                        </Button>
                      </Grid>
                  }
                </Grid>
              </Grid>
          }
        </AnimateHeight>

        <Grid item display={"flex"} justifyContent={"flex-end"} mt={2}>
          <Button variant="contained" size="small" sx={{ mr: 1 }}
                  onClick={onCancel} disabled={isLoading} color="secondary"
          >
            {t("auction.modals.common.cancel")}
          </Button>
          <Button variant="contained" color="primary" size="small"
                  onClick={saveReviewFile} disabled={isLoading}
          >
            {t("auction.modals.review.upload")}
          </Button>
        </Grid>
      </Box>
    </Modal>
  )
}
