import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { getAccessToken, refreshToken } from "../../utils/token";

const protectedInstance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/api/v1/protected`,
  headers: { "Content-Type": "application/json" }
});

const tokenExpiredCodes = ["bad_credentials", "authorization_service_failed"];
const companyNotActive = "company_not_active"
async function onRequestFulfilled(config: AxiosRequestConfig<any>) {
  const accessToken = await getAccessToken();
  if (!config.headers || !accessToken) { return }
  config.headers.Authorization = `Bearer ${accessToken}`;
  return config;
}
function onRequestRejected(error: any) { Promise.reject(error) }

async function onResponseFulfilled(response: AxiosResponse<any, any>) {
  
  // access token not expired
  if (!tokenExpiredCodes.includes(response.data.code)) { return response }

  try {
    await refreshToken();
    protectedInstance.request(response.config)
  }
  catch (error) { return Promise.reject(error) }
}
function onResponseRejected(error: any) { return Promise.reject(error) }

protectedInstance.interceptors.request.use(onRequestFulfilled, onRequestRejected);
protectedInstance.interceptors.response.use(onResponseFulfilled, onResponseRejected);

export default protectedInstance;
