import React, {useEffect, useState} from "react";

import {LocalizationProvider, MobileDateTimePicker} from "@mui/x-date-pickers";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns"
import ruLocale from 'date-fns/locale/ru';
import kkLocale from 'date-fns/locale/kk';
import {Divider, Grid, TextField, TextFieldProps, Typography} from "@mui/material";
import {Field, FieldProps, Formik, FormikProps, useFormik} from "formik";
import {initialValues, AuctionOrTenderValidationSchema, PriceOfferOrSingleSourceValidationSchema} from "./formik";
import {useSelector} from "react-redux";
import {useSnack} from "../../../../../utils/useSnack";
import {State} from "../../../../../redux/store";
import {NewAuctionDatesForm} from "../../../../../types/newAuction";
import {dateAfterHours, dateAfterDays, dateToMuiDateTimeString} from "../../../../../utils/dates";
import {AuctionType} from "../../../../../utils/enums";
import {useTranslation} from "react-i18next";

export const DatesFields = ({
                                formikRef, editingInitialState, currentAuctionType
                            }: {
    formikRef: React.RefObject<FormikProps<NewAuctionDatesForm>>;
    editingInitialState?: NewAuctionDatesForm;
    currentAuctionType: AuctionType;
}) => {
    const {t} = useTranslation();
    const onSubmit = () => {
    };
    const state = useSelector((state: State) => state);
    const currentTime: string | null = state.public.currentTime;
    const currentDate = currentTime ? new Date(currentTime) : new Date();
    const isAuctionOrTender = currentAuctionType === AuctionType.auction || currentAuctionType === AuctionType.tender;
    const isPriceOfferOrSingleSource = currentAuctionType === AuctionType.price_offer || currentAuctionType === AuctionType.single_source;

    return (
        <Formik innerRef={formikRef}
                initialValues={editingInitialState || initialValues}
                validationSchema={isAuctionOrTender
                    ? AuctionOrTenderValidationSchema(currentDate)
                    : PriceOfferOrSingleSourceValidationSchema()
                }
                onSubmit={onSubmit}
                validateOnMount
        >
            {(frops: FormikProps<NewAuctionDatesForm>) => (
                <Component
                    frops={frops}
                    currentDate={currentDate}
                    currentAuctionType={currentAuctionType}
                    isAuctionOrTender={isAuctionOrTender}
                    isPriceOfferOrSingleSource={isPriceOfferOrSingleSource}
                />
            )}
        </Formik>
    )
}

function Component({frops, currentDate, currentAuctionType, isAuctionOrTender, isPriceOfferOrSingleSource}: {
    frops: FormikProps<NewAuctionDatesForm>;
    currentDate: Date;
    currentAuctionType: AuctionType;
    isAuctionOrTender: boolean;
    isPriceOfferOrSingleSource: boolean;
}) {
    const {t, i18n} = useTranslation();
    const [approvementDatesError, setApprovementDatesError] = useState("");

    const setBidCloseTime = (frops: FormikProps<NewAuctionDatesForm>) => {
        const bidOpenTime = frops.values.bidOpenTime;
        const bidCloseTime = frops.values.bidCloseTime;

        if (bidOpenTime) {
            if (!bidCloseTime) {
                frops.setFieldValue("bidCloseTime", dateAfterHours(bidOpenTime, 1))
            } else {
                if (dateAfterHours(bidOpenTime, 1)?.getTime() !== bidCloseTime.getTime()) {
                    frops.setFieldValue("bidCloseTime", dateAfterHours(bidOpenTime, 1))
                }
            }
        } else if (bidCloseTime) {
            frops.setFieldValue("bidCloseTime", null)
        }
    }

    function approvementDatesCheck(frops: FormikProps<NewAuctionDatesForm>) {
        const regCloseTime = frops.values.registrationCloseTime;
        const bidOpenTime = frops.values.bidOpenTime;

        if (regCloseTime && bidOpenTime) {
            const timeDifference = bidOpenTime.getTime() - regCloseTime.getTime();

            // approvement time less than 1 hour
            if (timeDifference < 3600000) {
                const msInMinutes = Math.round((timeDifference / 60000) * 100) / 100;
                setApprovementDatesError(`${t("auction.dates.attention_approvement_less_than_hour")} (${msInMinutes} ${t("auction.dates.minutes")})`)
            } else {
                setApprovementDatesError("")
            }
        } else {
            setApprovementDatesError("")
        }
    }

    useEffect(() => {
        if (!isPriceOfferOrSingleSource) setBidCloseTime(frops);
        approvementDatesCheck(frops);
    }, [frops.values]);

    return (
        <LocalizationProvider
            dateAdapter={AdapterDateFns}
            adapterLocale={i18n.language == "kk" ? kkLocale : ruLocale}>
            <Grid container columnSpacing={3} rowSpacing={1}>
                <Grid item xs={12} sx={{mb: 1}}>
                    <Typography variant="h6">
                        {t("auction.sections.dates")}
                    </Typography>
                </Grid>

                {isAuctionOrTender
                    ? <>
                        <Grid item container columnSpacing={3} rowSpacing={1}>
                            <Grid item xs={12} sm={6} md={4}>
                                <MobileDateTimePicker
                                    disabled={!currentDate}
                                    minDateTime={currentDate
                                        ? new Date(currentDate)
                                        : new Date()}
                                    value={frops.values.registrationOpenTime}
                                    onChange={(newValue: any) => {
                                        frops.setFieldValue("registrationOpenTime", newValue)
                                    }}
                                    inputFormat="dd/MM/yyyy HH:mm"
                                    label={t("auction.dates.choose_date_and_time")}
                                    componentsProps={{
                                        actionBar: {
                                            actions: ['today', 'accept', 'cancel'],
                                        },
                                    }}
                                    renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) => (
                                        <Field name={"registrationOpenTime"}>
                                            {({field, form, meta}: FieldProps) => (
                                                <TextField {...params} fullWidth size="small" color="primary"
                                                           label={t("auction.fields.registrationOpenTime")}
                                                           error={(meta.touched && meta.error) ? true : false}
                                                           helperText={meta.touched && meta.error}
                                                           InputLabelProps={{shrink: field.value ? true : false}}
                                                />
                                            )}
                                        </Field>
                                    )}
                                />

                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <MobileDateTimePicker
                                    disabled={!frops.values.registrationOpenTime}
                                    minDateTime={frops.values.registrationOpenTime}
                                    maxDateTime={dateAfterDays(frops.values.registrationOpenTime, 30)}
                                    value={frops.values.registrationCloseTime}
                                    onChange={(newValue: any) => frops.setFieldValue("registrationCloseTime", newValue)}
                                    inputFormat="dd/MM/yyyy HH:mm"
                                    renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) => (
                                        <Field name={"registrationCloseTime"}>
                                            {({field, form, meta}: FieldProps) => (
                                                <TextField {...params} fullWidth size="small" color="primary"
                                                           label={t("auction.fields.registrationCloseTime")}
                                                           placeholder={!frops.values.registrationOpenTime
                                                               ? t("auction.dates.choose_reg_start_date")
                                                               : ""
                                                           }
                                                           error={(meta.touched && meta.error) ? true : false}
                                                           helperText={meta.touched && meta.error}
                                                           InputLabelProps={{shrink: true}}
                                                />
                                            )}
                                        </Field>
                                    )}
                                />
                            </Grid>
                        </Grid>
                        <Divider sx={{mt: 2, ml: "auto", mr: "auto", width: '85%'}}/>
                    </>
                    : null
                }

                <Grid item container columnSpacing={3} rowSpacing={1} sx={{mt: isAuctionOrTender ? 1 : 0}}>
                    <Grid item xs={12} sm={6} md={4}>
                        <MobileDateTimePicker
                            disabled={isAuctionOrTender
                                ? !frops.values.registrationCloseTime
                                : false
                            }
                            minDateTime={frops.values.registrationCloseTime ?? new Date(currentDate)}
                            maxDateTime={dateAfterDays(frops.values.registrationCloseTime, 30)}
                            value={frops.values.bidOpenTime}
                            onChange={(newValue: any) => frops.setFieldValue("bidOpenTime", newValue)}
                            inputFormat="dd/MM/yyyy HH:mm"
                            renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) => (
                                <Field name={"bidOpenTime"}>
                                    {({field, form, meta}: FieldProps) => (
                                        <TextField {...params} fullWidth size="small" color="primary"
                                                   label={t("auction.fields.bidOpenTime")}
                                                   placeholder={isAuctionOrTender
                                                       ? !frops.values.registrationCloseTime
                                                           ? t("auction.dates.choose_reg_close_date")
                                                           : ""
                                                       : ""
                                                   }
                                                   error={(meta.touched && meta.error) ? true : false}
                                                   helperText={meta.touched && meta.error}
                                                   InputLabelProps={{
                                                       shrink: isAuctionOrTender
                                                           ? true
                                                           : frops.values.bidOpenTime
                                                               ? true
                                                               : false
                                                   }}
                                        />
                                    )}
                                </Field>
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        {isPriceOfferOrSingleSource
                            ? <MobileDateTimePicker
                                disabled={!frops.values.bidOpenTime}
                                minDateTime={frops.values.bidOpenTime ?? new Date(currentDate)}
                                maxDateTime={dateAfterDays(frops.values.bidOpenTime, 30)}
                                value={frops.values.bidCloseTime}
                                onChange={(newValue: any) => frops.setFieldValue("bidCloseTime", newValue)}
                                inputFormat="dd/MM/yyyy HH:mm"
                                renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) => (
                                    <Field name={"bidCloseTime"}>
                                        {({field, form, meta}: FieldProps) => (
                                            <TextField {...params} fullWidth size="small" color="primary"
                                                       label={t("auction.fields.bidCloseTime")}
                                                       placeholder={t("auction.dates.choose_bid_open_date")}
                                                       error={(meta.touched && meta.error) ? true : false}
                                                       helperText={meta.touched && meta.error}
                                                       InputLabelProps={{
                                                           shrink: !frops.values.bidOpenTime
                                                               ? true
                                                               : frops.values.bidCloseTime
                                                                   ? true
                                                                   : false
                                                       }}
                                            />
                                        )}
                                    </Field>
                                )}
                            />

                            : <Field name={"bidCloseTime"}>
                                {({field, form, meta}: FieldProps) => (
                                    <TextField fullWidth size="small" color="primary"
                                               disabled={!frops.values.bidCloseTime}
                                               value={dateToMuiDateTimeString(field.value) || ""}
                                               label={t("auction.fields.bidCloseTime")}
                                               placeholder={t("auction.dates.choose_bid_open_date")}
                                               error={(meta.touched && meta.error) ? true : false}
                                               helperText={meta.touched && meta.error}
                                               InputProps={{readOnly: true}}
                                               InputLabelProps={{shrink: true}}
                                    />
                                )}
                            </Field>
                        }
                    </Grid>
                </Grid>

                {isAuctionOrTender
                    ? <>
                        <Divider sx={{mt: 2, ml: "auto", mr: "auto", width: '85%'}}/>
                        <Grid item container columnSpacing={3} rowSpacing={1} sx={{mt: 1}}>
                            <Grid item xs={12} sx={{mb: 1}}>
                                <Typography fontSize={12}>
                                    {t("auction.fields.aboutApprovementTime")}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <TextField
                                    fullWidth color="primary" type="text" size="small"
                                    label={t("auction.fields.approvementOpenTime")}
                                    placeholder={!frops.values.registrationCloseTime
                                        ? t("auction.dates.choose_reg_close_date")
                                        : ""
                                    }
                                    disabled={!frops.values.registrationCloseTime}
                                    value={frops.values.registrationCloseTime
                                        ? dateToMuiDateTimeString(frops.values.registrationCloseTime)
                                        : ""
                                    }
                                    error={approvementDatesError ? true : false}
                                    InputProps={{readOnly: true}}
                                    InputLabelProps={{shrink: true}}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <TextField
                                    fullWidth color="primary" type="text" size="small"
                                    label={t("auction.fields.approvementCloseTime")}
                                    placeholder={!frops.values.bidOpenTime
                                        ? t("auction.dates.choose_bid_open_date")
                                        : ""
                                    }
                                    disabled={!frops.values.bidOpenTime}
                                    value={frops.values.bidOpenTime
                                        ? dateToMuiDateTimeString(frops.values.bidOpenTime)
                                        : ""
                                    }
                                    error={approvementDatesError ? true : false}
                                    InputProps={{readOnly: true}}
                                    InputLabelProps={{shrink: true}}
                                />
                            </Grid>
                            {approvementDatesError
                                ? <Grid item xs={12} sx={{mt: 1}}>
                                    <Typography color="error">{approvementDatesError}</Typography>
                                </Grid>
                                : null
                            }
                        </Grid>
                    </>
                    : null
                }
            </Grid>
        </LocalizationProvider>
    )
}
