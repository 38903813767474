import { Paper, Box, Tabs, Tab, Typography } from "@mui/material";
import { AxiosError } from "axios";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { auctionService } from "../../../api/services/auction";
import { State } from "../../../redux/store";
import { AuctionCard, Participants, Bids, Participate, Result } from "./components";
import { useSelector, useDispatch } from "react-redux";
import { Company } from "../../../types/company";
import { Auction } from "../../../types/auction";
import { SET_AUCTION } from "../../../redux/reducers/auction";
import { useSnack } from "../../../utils/useSnack";
import { TabContext, TabPanel } from "@mui/lab";
import { AuctionState, AuctionType } from "../../../utils/enums";
import { switchTitleText } from "../../../utils/switchAuctionText";
import { useTranslation } from "react-i18next";

export default function AuctionDetails({
  auctionType, auctionPath, setAuctionEditing
}: {
  auctionType?: AuctionType;
  auctionPath: string;
  setAuctionEditing?: React.Dispatch<React.SetStateAction<boolean>>
}) {
  const { t } = useTranslation();
  const [tabValue, setTabValue] = useState("card");
  const handleTabChange = (event: React.SyntheticEvent, newTabValue: string) => {
    setTabValue(newTabValue);
  };

  const { snack } = useSnack();
  const location = useLocation();
  const state = useSelector((state: State) => state);
  const dispatch = useDispatch();

  const pathArray = location.pathname.split('/');
  const auctionId = pathArray[pathArray.length - 1];
  const company: Company | null = state.company.company;

  const [loading, setLoading] = useState(false);
  const [auction, setAuction] = useState<Auction | null>(null);

  const detailsErrorMessage = (): string => {
    switch (auctionType) {
      case AuctionType.tender:
        return t("snack.error.tender_details")
      case AuctionType.price_offer:
        return t("snack.error.price-offer_details")
      case AuctionType.single_source:
        return t("snack.error.single-source_details")
      default:
        return t("snack.error.auction_details")
    }
  }

  async function getAuction() {
    if (!company) { return }
    setLoading(true);

    try {
      const response = await auctionService.getAuction(company.id, auctionId);
      if (response.status === "success" && response.data) {
        setAuction(response.data);
        dispatch({ type: SET_AUCTION, payload: response.data });
        return
      }
      else { snack(detailsErrorMessage(), "error") }

    }
    catch (error) { const err = error as AxiosError }
    finally { setLoading(false) }
  }

  useEffect(() => {
    if (company?.id) getAuction();
    return () => { setAuction(null) };
  }, [company]);

  const isParticipant = !!auction?.canParticipate;
  const auctionOrTender = auction?.auctionType === AuctionType.auction || auction?.auctionType === AuctionType.tender;

  return (
    <Paper sx={{ pl: { xs: 0, sm: 4}, pr: { xs: 0, sm: 4}, pt: 2, pb: 4 }} elevation={10}>
      <TabContext value={tabValue}>
        <Box sx={{ width: "100%", mb: 3, borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={tabValue} onChange={handleTabChange}
                textColor="primary" indicatorColor="primary" variant="scrollable"
          >
            <Tab key="card" value="card" sx={{ maxWidth: "100%" }}
              label={
                <Typography sx={{ fontSize: { xs: 16, sm: 18, md: 20 }, textTransform: "none" }}>
                  {switchTitleText(auction)} <b>{auction ? `№${auction.auctionNo}` : ""}</b>
                </Typography>
              }
            />

            {(auction && company)
              // является организатором
              ? company.itn === auction?.company.itn
                ? [auctionOrTender
                    ? <Tab key="participants" value="participants"
                        label={
                          <Typography sx={{ fontSize: { xs: 14, sm: 16, md: 20 }, textTransform: "none" }}>
                            {t("auction.tabs.participants")}
                          </Typography>
                        }
                      />
                    : null
                    ,
                    <Tab key="bids" value="bids"
                      label={
                        <Typography sx={{ fontSize: { xs: 14, sm: 16, md: 20 }, textTransform: "none" }}>
                          {auction?.auctionType === AuctionType.auction
                            ? t("auction.tabs.auctionBids")
                            : t("auction.tabs.otherBids")
                          }
                        </Typography>
                      }
                    />
                  ]

                // является участником
                : isParticipant
                    ? <Tab key="bids" value="bids"
                        label={
                          <Typography sx={{ fontSize: { xs: 14, sm: 16, md: 20 }, textTransform: "none" }}>
                            {auction?.auctionType === AuctionType.auction
                              ? t("auction.tabs.auctionBids")
                              : t("auction.tabs.otherBids")
                            }
                          </Typography>
                        }
                      />
                    : <Tab key="participate" value="participate"
                        label={
                          <Typography sx={{ fontSize: { xs: 14, sm: 16, md: 20 }, textTransform: "none" }}>
                            {t("auction.tabs.participate")}
                          </Typography>
                        }
                      />
              : null
            }

            {auction && company && auction.state === AuctionState.finished
              ? <Tab key="result" value="result"
                  label={
                    <Typography sx={{ fontSize: { xs: 14, sm: 16, md: 20 }, textTransform: "none" }}>
                      {t("auction.tabs.result")}
                    </Typography>
                  }
                />
              : null
            }
          </Tabs>
        </Box>

        <TabPanel value="card">
          <AuctionCard
            auctionType={auctionType} auctionPath={auctionPath}
            auction={auction} setAuction={setAuction}
            loading={loading} setLoading={setLoading}
            setAuctionEditing={setAuctionEditing}
          />
        </TabPanel>

        <TabPanel value="participants">
          <Participants
            auctionPath={auctionPath}
            auctionId={auctionId}
            auction={auction}
            getAuction={getAuction}
          />
        </TabPanel>

        <TabPanel value="participate">
          <Participate
            auctionId={auctionId}
            auction={auction}
            getAuction={getAuction}
          />
        </TabPanel>

        <TabPanel value="bids">
          <Bids
            auctionId={auctionId}
            auction={auction} getAuction={getAuction}
            participant={isParticipant}
          />
        </TabPanel>

        <TabPanel value="result">
          <Result
            auctionId={auctionId}
            auction={auction} getAuction={getAuction}
            participant={isParticipant}
          />
        </TabPanel>
      </TabContext>
		</Paper>
  )
}
