import { Box, Typography, Grid, Card, CardContent, LinearProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { publicService } from "../../../api/services/public";
import Container from "../../../common/Container";
import { Announcement } from "../../../types/announcement";
import { useSnack } from "../../../utils/useSnack";

export default function Announcements() {
  const { t } = useTranslation();
  const { snack } = useSnack();

  const [announcements, setAnnouncements] = useState<Announcement[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const [page, setPage] = useState(0);
	const [pageSize, setPageSize] = useState(5);
	const [totalElements, setTotalElements] = useState(0);

  const onPageChange = (newPage: number) => setPage(newPage);
	const onPageSizeChange = (newSize: number) => {setPage(0); setPageSize(newSize)};

  async function getAnnouncements() {
    try {
      setLoading(true);
      const response = await publicService.getAnnouncements(page, pageSize);

      if (response.status === "success" && response.data) {
        setAnnouncements(response.data.data);
        setTotalElements(response.data.totalElements);
      }
    } catch (error) {
      snack(t("snack.error.announcements_registry"), "error");
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getAnnouncements();
  }, [page, pageSize]);

  return (
    <Box>
      <Container>
        <Box>
          <Box marginBottom={4}>
            <Typography
              sx={{
                textTransform: 'uppercase',
                fontWeight: 'medium',
              }}
              gutterBottom
              color={'secondary'}
              align={'center'}
            >
              {t("announcements_page.announcements")}
            </Typography>
          </Box>

          <Grid container spacing={2}>
            {loading 
              ? <Box width="90%" margin="0 auto">
                  <LinearProgress sx={{ mt: 3, mb: 1 }} color="primary" />
                  <Typography textAlign="center">
                    {t("announcements_page.loading")}
                  </Typography>
                </Box>
              : announcements.map((item, i) => (
                  <Grid item xs={12} key={i} data-aos={'fade-up'}>
                    <Card>
                      <CardContent>
                        <Box
                          display={'flex'}
                          justifyContent={'space-between'}
                          alignItems={{
                            xs: 'stretched',
                            sm: 'flex-start',
                            md: 'center',
                          }}
                          flexDirection={{ xs: 'column', md: 'row' }}
                        >
                          <Box maxWidth={{ xs: '100%', md: '70%' }}>
                            <Typography variant={'caption'} color="text.secondary">
                              {item.publishedTime.replace('T', " ") ?? item.publishedTime}
                            </Typography>
                            <Typography variant={'h6'} gutterBottom>
                              {item.title}
                            </Typography>
                            <Typography color="text.secondary">
                              {item.content}
                            </Typography>
                          </Box>
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
          </Grid>
        </Box>
      </Container>
    </Box>
  )
}