export const pages = [
  {
    title: 'pages.pages',
    id: 'landing-pages',
    pages: [
      {
        title: 'pages.main_page',
        href: '/',
      },
      {
        title: 'pages.contests',
        href: '/page-auctions',
      },
      {
        title: 'pages.documentation',
        href: '/page-documentation',
      },
      {
        title: 'pages.contacts',
        href: '/page-contacts',
      },
      {
        title: 'pages.announcements',
        href: '/page-announcements',
      },
    ],
  },
  {
    title: 'pages.auth',
    id: 'auth-pages',
    pages: [
      {
        title: 'pages.login',
        href: '/page-login',
      },
      {
        title: 'pages.signup',
        href: '/page-signup',
      },
    ],
  }
];
