import React, { useState } from "react";
import { Box, Button, Divider, Grid, LinearProgress, List, ListItem, ListItemButton, 
  ListItemText, Modal, Pagination, TextField, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import CloseIcon from '@mui/icons-material/Close';
import { AxiosError } from "axios";
import AnimateHeight from "react-animate-height";
import { FieldArrayRenderProps } from "formik";
import { PaginationResponse } from "../../../../../../types/api";
import { ProductsObject, RubricItem } from "../../../../../../types/newAuction";
import { auctionService } from "../../../../../../api/services/auction";
import { DictionaryObject } from "../../../../../../types/dictionary";
import { useSnack } from "../../../../../../utils/useSnack";
import { modalStyle } from "../../../../../../common/modalStyle";
import { useTranslation } from "react-i18next";

export const ProductModal = ({
  productBundle, productType, items, arrayHelpers
}: {
  productBundle: {
    isOpen: boolean;
    onClose: () => void;
    currentModalIdx: number;
  };
  productType: DictionaryObject;
  items: RubricItem[];
  arrayHelpers: FieldArrayRenderProps;
}) => {
  const { isOpen, onClose, currentModalIdx } = productBundle;
  const { snack } = useSnack();
  const { t } = useTranslation();

  const [searchText, setSearchText] = useState("");
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [totalPages, setTotalPages] = useState(0);

  const [isLoading, setIsLoading] = useState(false);
  const [productsResponse, setProductsResponse] = useState<PaginationResponse<ProductsObject[]>>({
    code: "", data: null, status: "success"
  });
  const [products, setProducts] = useState<ProductsObject[]>([]);

  const searchProducts = async () => {
    try {
      setIsLoading(true);
      const response = await auctionService.searchProducts(
        productType, searchText, page, pageSize
      );
      if (response.status === "success") {
        setProductsResponse(response);
        setProducts(response.data?.data ? response.data.data : []);
        setTotalPages(response.data?.totalPages ? response.data.totalPages : 0);
      }
      else { snack(t("snack.error.default"), "error"); }
    }
    catch (error) {
      const err = error as AxiosError;
      snack(t("snack.error.default"), "error"); 
    }
    finally { setIsLoading(false) }
  };
  const onSearch = () => {
    setPage(0);
    searchProducts();
  };

  const onProductPick = (product: ProductsObject) => {
    arrayHelpers.replace(currentModalIdx, { ...items[currentModalIdx],
      productCode: product.code,
      productName: product.title,
      productBrief: product.brief,
    });
    onClose();
  };

  const handleClose = () => { onClose(); setProducts([]) };

  React.useEffect(() => { searchProducts() }, [page]);
  React.useEffect(() => { onSearch() }, [isOpen]);

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <Box sx={modalStyle}>
        {/* header */}
          <Grid container spacing={2} justifyContent="space-between" sx={{ mb: 2 }}>
            <Grid item>
              <Typography component="h5" variant="h5" sx={{ fontSize: { xs: 14, sm: 20 } }}>
                {t("auction.rubric.search_product_type")} <b>"{productType.label}"</b>
              </Typography>
            </Grid>
            <Grid item xs={2.2} sm={1.2}>
              <Button onClick={onClose} sx={{ 
                color: "black"
              }}>
                <CloseIcon />
              </Button>
            </Grid>
          </Grid>
        {/* / header */}

        {/* search */}
          <Grid container spacing={2}>
            <Grid item xs={9}>
              <TextField 
                fullWidth color="primary" type="text" size="small"
                name="searchText" label={t("auction.modals.product.search_by_name")}
                value={searchText} onChange={e => setSearchText(e.target.value)}
                onKeyPress={e => {if (e.key === "Enter") { onSearch() } }}
              />
            </Grid>
            <Grid item xs={3}>
              <LoadingButton fullWidth color="primary" variant="contained"
                             onClick={onSearch} loading={isLoading}
              >
                {t("auction.modals.product.search")}
              </LoadingButton>
            </Grid>
          </Grid>
        {/* / search */}

        <AnimateHeight height={isLoading ? 250 : "auto"} duration={200}>
          {isLoading
            ? <Box>
                <LinearProgress sx={{ mt: 3, mb: 1 }} color="primary" />
                <Typography>{t("auction.modals.product.searching")}</Typography>
              </Box>
            : productsResponse
              ? products.length
                ? <List sx={{ minHeight: 250, maxHeight: { xs: 350, sm: 570 }, overflowY: "scroll" }}>
                    {products.map((product, idx) => (
                      <React.Fragment key={idx}>
                        <ListItem disablePadding>
                          <ListItemButton onClick={() => onProductPick(product)} style={{
                            justifyContent: "space-between"
                          }}>
                            <ListItemText
                              primary={product.title}
                              secondary={
                                <React.Fragment>
                                  <Typography component={"span"} sx={{ display: "block" }}>
                                    {product.code}
                                  </Typography>
                                  <Typography component={"span"} sx={{ display: "block" }}>
                                    {product.brief}
                                  </Typography>
                                </React.Fragment>
                              }
                            />
                          </ListItemButton>
                        </ListItem>

                        <Divider />
                      </React.Fragment>
                    ))}
                    <Box mt={2} display="flex" justifyContent="center">
                      <Pagination 
                        page={page + 1} count={totalPages}
                        onChange={(e, page) => setPage(page - 1)}
                      />
                    </Box>
                  </List>
                : <Typography fontSize={14} mt={2}>
                    {t("auction.modals.product.not_found")}
                  </Typography>
              : null
          }
        </AnimateHeight>

      </Box>
    </Modal>
  )
}
