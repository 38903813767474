import { Grid, Button, Autocomplete, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Dictionary } from "../types/dictionary";

export default function({
  onSign, isSigning, signingError, storageType, signed
}: {
  onSign: () => void;
  isSigning: boolean;
  signingError: string;
  storageType: Dictionary;
  signed?: boolean;
}) {
  const { t } = useTranslation();

  return (
      <Grid item container spacing={2} xs={12}>
          <Grid item lg={4} md={4} sm={4} xs={12}>
         {/*<Grid item container lg={6} md={7} sm={8} xs={10} spacing={2}>*/}
           {/*<Grid item lg={4} md={4} sm={5} xs={12}>*/}
            <Button fullWidth variant="contained" size="large" color="primary"
                    onClick={onSign} disabled={signed || isSigning || signingError ? true : false}
            >
              {signed ? t("auction.signed") : t("auction.sign")}
            </Button>
          </Grid>
          <Grid item lg={8} md={8} sm={8} xs={12}>
          {/*<Grid item lg={8} md={8} sm={7} xs={12}>*/}
            <Autocomplete
              disablePortal size="small" sx={{ mb: .5 }}
              disabled={signed || isSigning || signingError ? true : false }
              options={storageType.dictionary}
              isOptionEqualToValue={(option, value) => option.value === value.value}
              value={storageType.selected}
              onChange={storageType.onSelectChange}
              fullWidth
              renderInput={params =>
                <TextField {...params} fullWidth color="primary"
                  name="storageType" label={t("auction.dictionary.storageType")}
                />
              }
            />
          </Grid>
        </Grid>
  )
}
