import React from 'react';
import Box from '@mui/material/Box';
import Container from '../../../common/Container';
import { Form } from './components';
import {Step, StepLabel, Stepper, Typography} from "@mui/material";
import {t} from "i18next";

const SignupSimpleV2 = () => {

  const steps = ['Select campaign settings', 'Create an ad group', 'Create an ad'];

  const isStepFailed = (step: number) => {
    return step === 1;
  };

  return (
    <Box
      position={'relative'}
      minHeight={'calc(100vh - 247px)'}
      display={'flex'}
      alignItems={'center'}
      justifyContent={'center'}
      sx={{flexDirection: 'column'}}
      height={'100%'}
    >

      <Box marginBottom={4}>
        <Typography
            sx={{
              textTransform: 'uppercase',
              fontWeight: 'medium',
            }}
            gutterBottom
            color={'textSecondary'}
        >
          {t("pages.signup")}
        </Typography>
        <Typography
            variant="h4"
            sx={{
              fontWeight: 700,
            }}
        >
          {t("signup_page.create_account")}
        </Typography>
      </Box>

        <Stepper activeStep={1}>
          {steps.map((label, index) => {
            const labelProps: {
              optional?: React.ReactNode;
              error?: boolean;
            } = {};
            if (isStepFailed(index)) {
              labelProps.optional = (
                  <Typography variant="caption" color="error">
                    Alert message
                  </Typography>
              );
              labelProps.error = true;
            }

            return (
                <Step key={label}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
            );
          })}
        </Stepper>
      <Container maxWidth={600}>
        <Form />
      </Container>
    </Box>
  );
};

export default SignupSimpleV2;
