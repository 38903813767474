import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Container from '../../../common/Container';
import { Typography, Grid, Card, CardContent, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

const Documentation = () => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Box>
      <Container>
        <Box>
          <Box marginBottom={4}>
            <Typography
              sx={{
                textTransform: 'uppercase',
                fontWeight: 'medium',
              }}
              gutterBottom
              color={'secondary'}
              align={'center'}
            >
              {t("documentation_page.documentation")}
            </Typography>
            <Box
              component={Typography}
              fontWeight={700}
              variant={'h3'}
              align={'center'}
              gutterBottom
              data-aos={'fade-up'}
            >
              {t("documentation_page.marketplace_bidhub")}
            </Box>
            <Typography
              variant={'h6'}
              component={'p'}
              color={'textSecondary'}
              align={'center'}
              data-aos={'fade-up'}
            >
              {t("documentation_page.rules")}
            </Typography>
          </Box>
          <Grid container spacing={2}>
            {[
              {
                title: t("documentation_page.docs.gov_purchase_law"),
                subtitle: '',
                href: '/docs/Закон РК О государственных закупках 07.03.22 г.pdf',
                isHighlighted: true,
              },
              {
                title: t("documentation_page.docs.bidhub_work_rules"),
                subtitle: '',
                href: '',
                isHighlighted: false,
              },
              {
                title: t("documentation_page.docs.customer_instructions"),
                subtitle: '',
                href: '',
                isHighlighted: false,
              },
              {
                title: t("documentation_page.docs.supplier_instructions"),
                subtitle: '',
                href: '',
                isHighlighted: false,
              },
            ].map((item, i) => (
              <Grid item xs={12} key={i} data-aos={'fade-up'}>
                <Card>
                  <CardContent>
                    <Box
                      display={'flex'}
                      justifyContent={'space-between'}
                      alignItems={{
                        xs: 'stretched',
                        sm: 'flex-start',
                        md: 'center',
                      }}
                      flexDirection={{ xs: 'column', md: 'row' }}
                    >
                      <Box maxWidth={{ xs: '100%', md: '70%' }}>
                        <Typography variant={'h6'} gutterBottom>
                          {item.title}
                        </Typography>
                        <Typography color="text.secondary">
                          {item.subtitle}
                        </Typography>
                      </Box>
                      <a href={item.href ? item.href : undefined} download style={{ textDecoration: "none" }}>
                        <Box
                          component={Button}
                          variant={item.isHighlighted ? 'contained' : 'outlined'}
                          size={'large'}
                          marginTop={{ xs: 2, md: 0 }}
                          marginLeft={{ md: 2 }}
                        >
                          {t("documentation_page.download_document")}
                        </Box>
                      </a>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

Documentation.propTypes = {
  themeMode: PropTypes.string.isRequired,
};

export default Documentation;
