import {useEffect, useState} from 'react';
import {Box, Button, Grid, IconButton, TextField, Typography} from '@mui/material';
import {DataGrid} from '@mui/x-data-grid';
import ClearIcon from '@mui/icons-material/Clear';
import {useTranslation} from 'react-i18next';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {useSnack} from '../../../utils/useSnack';
import {moderatorService} from '../../../api/services/moderator';
import {Company} from '../../../types/company';
import Container from '../../../common/Container';
import NoRowsOverlay from '../../../common/NoRowsOverlay';
import {Column, columnStyle, CompanyItnColumn, OwnerNameColumn} from '../../../common/Columns';

export default function Companies() {
    const {snack} = useSnack();
    const {t} = useTranslation();
    const [searchParams, setSearchParams] = useSearchParams();
    const [companies, setCompanies] = useState<Company[]>([]);
    const [loading, setLoading] = useState(false);
    const [searchText, setSearchText] = useState(searchParams.get("text") || "");
    const [page, setPage] = useState(+(searchParams.get("page") || "0"));
    const [pageSize, setPageSize] = useState(+(searchParams.get("pageSize") || "5"));
    const [totalElements, setTotalElements] = useState(0);

    const search = (text: string) => getCompanies(text);
    const onClearSearch = () => {
        setSearchText("");
        search("")
    };
    const onPageChange = (newPage: number) => setPage(newPage);
    const onPageSizeChange = (newSize: number) => {
        setPage(0);
        setPageSize(newSize)
    };

    const getCompanies = async (text: string) => {
        try {
            setLoading(true);
            if (!!text) {
                searchParams.set("text", text)
            } else {
                searchParams.delete("text")
            }
            const response = await moderatorService.getCompanies(text, page, pageSize);
            if (response.status === "success" && response.data) {
                setCompanies(response.data.data);
                setTotalElements(response.data.totalElements);
            } else {
                snack(t("snack.error.companies_registry"), "error")
            }
        } catch (err) {
            snack(t("snack.error.companies_registry"), "error")
        } finally {
            setLoading(false)
        }
    };

    const navigate = useNavigate();
    const goToCompanyDetails = (id: string) => {
        navigate(`/moderator-companies/${id}`, { state: { text: searchText, page: page, pageSize: pageSize }});
    };

    useEffect(() => {
        getCompanies(searchText)
    }, [page, pageSize, searchParams]);

    return (
        <Box>
            <Container paddingY={{xs: 2, sm: 4}} maxWidth="unset">
                <Box marginBottom={4}>
                    <Typography
                        variant="h3"
                        gutterBottom
                        sx={{
                            fontWeight: 700,
                        }}
                    >
                        {t("cabinet.companies.registry")}
                    </Typography>
                </Box>

                <Grid item container spacing={2} alignItems={"center"} mb={2}>
                    <Grid item xs={12} sm={4} md={7} lg={9}>
                        <TextField fullWidth color="primary" variant="outlined" size="small"
                                   label={t("cabinet.companies.search_companies")}
                                   value={searchText} onChange={e => setSearchText(e.target.value)}
                                   onKeyPress={e => {
                                       if (e.key === "Enter") {
                                           search(searchText)
                                       }
                                   }}
                                   InputProps={{
                                       endAdornment: searchText ? (
                                           <IconButton size="small" onClick={onClearSearch}>
                                               <ClearIcon/>
                                           </IconButton>
                                       ) : undefined
                                   }}
                        />
                    </Grid>

                    <Grid item xs={12} sm={3}>
                        <Button fullWidth color="primary" variant="contained"
                                onClick={() => search(searchText)}
                        >
                            {t("cabinet.companies.search")}
                        </Button>
                    </Grid>
                </Grid>

                <Box>
                    <DataGrid
                        components={{NoRowsOverlay: () => <NoRowsOverlay/>}}
                        sx={{
                            height: 600,
                            "& .MuiDataGrid-cell": {
                                whiteSpace: "pre-wrap"
                            },
                        }}
                        columnBuffer={4}
                        rows={companies}
                        columns={[
                            CompanyItnColumn(200, columnStyle),
                            Column("title", 350, columnStyle),
                            OwnerNameColumn(200, columnStyle),
                            Column("ownershipForm", 200, columnStyle, "ownership")
                        ]}
                        onRowClick={(rowData) => goToCompanyDetails(rowData.row.id)}
                        page={page} onPageChange={onPageChange}
                        pageSize={pageSize} onPageSizeChange={onPageSizeChange}
                        rowCount={totalElements} rowsPerPageOptions={[5, 10, 15]}
                        loading={loading} paginationMode="server"
                        density="comfortable" style={{whiteSpace: "pre-wrap"}}
                    />
                </Box>
            </Container>
        </Box>
    );
};
