import React, { useEffect } from "react";
import { Autocomplete, Grid, TextField, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Field, FieldProps, Formik, FormikProps } from "formik";
import { initialValues, ValidationSchema } from "./formik";
import { Company, Employee } from "../../../../../types/company";
import { useSnack } from "../../../../../utils/useSnack";
import { companyService } from "../../../../../api/services/company";
import { SET_COMPANY, SET_EMPLOYEES } from "../../../../../redux/reducers/company";
import { State } from "../../../../../redux/store";
import { NewAuctionCustomerForm } from "../../../../../types/newAuction";
import digitsOnly from "../../../../../utils/digitsOnly";
import { useTranslation } from "react-i18next";

export const CustomerFields = ({
  formikRef, editingInitialState
}: {
  formikRef: React.RefObject<FormikProps<NewAuctionCustomerForm>>;
  editingInitialState?: NewAuctionCustomerForm
}) => {
  const onSubmit = (values: NewAuctionCustomerForm) => {};
  const { t } = useTranslation();
  
  const onSelectChange = (
    e: React.SyntheticEvent<Element, Event>,
    newValue: Employee | null,
  ) => {if (newValue) {formikRef.current?.setFieldValue("supervisor", newValue)}};

  const state = useSelector((state: State) => state);
  const company: Company | null = state.company.company;
	const employees: Employee[] = state.company.employees;

  const dispatch = useDispatch();
  const { snack } = useSnack();

  const getEmployees = async () => {
    if (!company) { return }

		try {
      const response = await companyService.getEmployees(company.id);
      dispatch({ type: SET_EMPLOYEES, payload: response.data });
    }
    catch (err) { snack(t("snack.error.employees"), "error"); }
	};
	useEffect(() => {if (company?.id) { getEmployees() }}, [company]);
	useEffect(() => {
    if (employees?.length && !editingInitialState) {
      formikRef.current?.setFieldValue("supervisor", employees[0])
    }
  }, [employees]);

  return (
    <Formik innerRef={formikRef}
      initialValues={editingInitialState || initialValues} 
      validationSchema={ValidationSchema()}
      onSubmit={onSubmit}
      validateOnMount
    >
      {(frops: FormikProps<NewAuctionCustomerForm>) => (
        <Grid container>
          <Grid container columnSpacing={3} rowSpacing={1}>
            <Grid item xs={12} sx={{ mb: 1 }}>
              <Typography variant="h6">
                {t("auction.sections.customer")}
              </Typography>
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <TextField
                fullWidth color="primary" type="text" size="small"
                value={company?.title ?? ""} name="title" 
                label={t("auction.fields.companyTitle")}
                InputProps={{ readOnly: true }}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <TextField
                fullWidth color="primary" type="text" size="small"
                value={digitsOnly(company?.itn) ?? ""} name="itn" 
                label={t("auction.fields.companyItn")}
                InputProps={{ readOnly: true }}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <TextField
                fullWidth color="primary" type="text" size="small"
                value={company?.actualAddress ?? ""} name="actualAddress" 
                label={t("auction.fields.actualAddress")}
                InputProps={{ readOnly: true }}
              />
            </Grid>
          </Grid>

          <Grid container columnSpacing={3} rowSpacing={1} sx={{ mt: 2 }}>
            <Grid item xs={12} sm={6} md={4}>
              <Autocomplete
                disablePortal size="small"
                options={employees || []}
                isOptionEqualToValue={(option, value) => option.userId === value.userId}
                getOptionLabel={option => option.name}
                value={frops.values.supervisor.userId ? frops.values.supervisor : null} 
                onChange={onSelectChange}
                renderInput={params => 
                  <Field name={"supervisor"}>
                    {({ field, form, meta }: FieldProps) => (
                      <TextField {...params} fullWidth color="primary" 
                                label={t("auction.fields.supervisor")}
                                error={(meta.touched && meta.error) ? true : false}
                                helperText={meta.touched && meta.error}
                      /> 
                    )}
                  </Field>
                }
              />
            </Grid>
          </Grid>
        </Grid>
      )}
    </Formik>
  )
}
