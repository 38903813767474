import { AnyAction } from "redux"

export const SET_COMPANIES_LOADING = "SET_COMPANIES_LOADING";
export const SET_COMPANIES = "SET_COMPANIES";
export const SET_NO_COMPANIES = "SET_NO_COMPANIES";
export const SET_COMPANY = "SET_COMPANY";
export const CLEAR_COMPANY = "CLEAR_COMPANY";
export const SET_EMPLOYEES = "SET_EMPLOYEES";

const initialState = {
  loading: true,
  companies: [],
  noCompanies: false,
  company: null,
  employees: []
}

export const companyReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case SET_COMPANIES_LOADING: return { ...state,
      loading: action.payload
    }
    
    case SET_COMPANIES: return { ...state,
      companies: action.payload
    }

    case SET_NO_COMPANIES: return { ...state,
      noCompanies: action.payload
    }
    
    case SET_COMPANY: return { ...state,
      company: action.payload
    }

    case CLEAR_COMPANY: return { ...state,
      company: initialState.company
    }

    case SET_EMPLOYEES: return { ...state,
      employees: action.payload
    }

    default: return state
  }
}
