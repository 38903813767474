import * as Yup from "yup";
import { NewAuctionParticipantForm } from "../../../../../types/newAuction";
import {t} from "i18next";

export const initialValues: NewAuctionParticipantForm = {
  participantItn: "",
  participantId: ""
};

export function ValidationSchema() {
  return Yup.object({
    participantItn: Yup.string()
      .required(t("validation.required")),
    participantId: Yup.string()
      .required(t("validation.participantId")),
  });
}